<template>
  <a-form-model
    ref="formRef"
    :model="form"
    :rules="rules"
    :label-col="formItemLayout.labelCol"
    :wrapper-col="formItemLayout.wrapperCol"
  >
    <a-row>
      <a-col :span="24">
        <a-form-model-item
          :label="$t('system.dic.form.dictType.label')"
          prop="dictType"
        >
          <a-select
            :getPopupContainer="trigger => trigger.parentNode"
            v-model="form.dictType"
            :placeholder="$t('system.dic.form.dictType.placeholder')"
            allowClear
          >
            <a-select-option
              v-for="op in dictTypeList"
              :key="op.dictCode"
              :value="op.dictCode"
            >
              {{ op.dictName }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
      </a-col>
      <a-col :span="24">
        <a-form-model-item
          :label="$t('system.dic.form.dictCode.label')"
          prop="dictCode"
        >
          <a-input
            v-model="form.dictCode"
            :placeholder="$t('system.dic.form.dictCode.placeholder')"
          />
        </a-form-model-item>
      </a-col>
      <a-col :span="24">
        <a-form-model-item
          :label="$t('system.dic.form.dictName.label')"
          prop="dictName"
        >
          <a-input
            v-model="form.dictName"
            :placeholder="$t('system.dic.form.dictName.placeholder')"
          />
        </a-form-model-item>
      </a-col>
      <a-col :span="24">
        <a-form-model-item
          :label="$t('system.dic.form.sort.label')"
          prop="sort"
        >
          <a-input-number
            v-model="form.sort"
            :max="99999999"
            :placeholder="$t('system.dic.form.sort.placeholder')"
          />
        </a-form-model-item>
      </a-col>
      <a-col :span="24">
        <a-form-model-item
          :label="$t('system.dic.form.state.label')"
          prop="state"
        >
          <a-switch v-model:checked="form.state" />
        </a-form-model-item>
      </a-col>
      <a-col :span="24">
        <a-form-model-item
          :label="$t('system.dic.form.dictIcon.label')"
          prop="dictIcon"
        >
          <ec-form-upload
            :format="['image/jpeg', 'image/jpg', 'image/png']"
            :fileList.sync="fileList"
            :size-max="51200"
            :number-max="1"
            @change="onFileUploadChange"
          />
        </a-form-model-item>
      </a-col>
      <a-col :span="24">
        <a-form-model-item
          :label="$t('system.dic.form.remark.label')"
          prop="remark"
        >
          <a-textarea
            v-model="form.remark"
            :auto-size="{ minRows: 3, maxRows: 5 }"
            :placeholder="$t('system.dic.form.remark.placeholder')"
          />
        </a-form-model-item>
      </a-col>
    </a-row>
  </a-form-model>
</template>

<script>
import EcFormUpload from "@/components/EcFormUpload.vue";
import { FETCH_CODE } from "@/config";
import { getDic } from "@/api";
import { langMixin } from "@/mixins";

const formEmpty = {
  dictType: undefined,
  dictCode: null,
  dictName: null,
  dictIcon: null,
  sort: null,
  state: true,
  remark: null,
};

export default {
  name: "DicForm",
  components: { EcFormUpload },
  props: {
    data: Object,
  },
  mixins: [langMixin],
  data() {
    return {
      form: {
        ...formEmpty,
      },
      rules: {
        dictType: [
          { required: true, message: this.$t("system.dic.form.dictType.required") },
        ],
        dictCode: [
          { required: true, message: this.$t("system.dic.form.dictCode.required") },
          { max: 100, message: this.$t("system.dic.form.dictCode.max", { length: 100 }) },
        ],
        dictName: [
          { required: true, message: this.$t("system.dic.form.dictName.required") },
          { max: 100, message: this.$t("system.dic.form.dictName.max", { length: 100 }) },
        ],
        sort: [
          { required: true, message: this.$t("system.dic.form.sort.required") },
          { type: "number", message: this.$t("system.dic.form.sort.number") },
        ],
        state: [
          { required: true, message: this.$t("system.dic.form.state.required") },
        ],
        remark: [
          { max: 500, message: this.$t("system.dic.form.remark.max", { length: 500 }) },
        ],
      },
      fileList: [],
      dictTypeList: [],
    };
  },
  computed: {
    formItemLayout() {
      return this.calcFormItemLayout([6, 8]);
    }
  },
  methods: {
    onFileUploadChange(fileList) {
      const file = fileList[0];
      if (!file || !file.response) return;
      const { data } = file.response;
      if (!data) return;
      this.form.dictIcon = data;
    },
    onValid() {
      const result = { flag: FETCH_CODE.SUCCESS.KEY, data: this.form, msg: "" };
      return new Promise((resolve) => {
        this.$refs.formRef.validate((valid, errors) => {
          if (!valid) {
            result.flag = FETCH_CODE.WARNING.KEY;
            result.msg = Object.values(errors)[0]?.[0]?.message;
            resolve(result);
            return;
          }
          resolve(result);
        });
      });
    },
    onReset() {
      this.$refs.formRef.resetFields();
      this.form = {
        ...this.form,
        ...formEmpty,
      };
    },
    onInit() {
      if (this.data && this.data.id) {
        this.form = this.data;

        if (this.data.dictIcon) {
          this.fileList = [{
            uid: "-1",
            name: "icon.png",
            status: "done",
            url: this.data.dictIcon,
          }];
        }
      }
    },
    async getDictTypeList() {
      const res = await getDic({ code: "dict_type" });
      this.dictTypeList = res.data;
    },
  },
  mounted() {
    this.getDictTypeList();
    this.onInit();
  },
};
</script>

<style lang="less" scoped></style>
