<template>
  <div>
    <ListFilter
      ref="listFilterRef"
      :formList="formList"
      @filter="onSearch"
      @reset="onReset"
      :loading="loading"
    >
      <a-form-model-item
        slot="type"
        :label="$t('order.gift.name')"
      >
        <a-select
          :getPopupContainer="trigger => trigger.parentNode"
          size="large"
          v-model="form.type"
          :placeholder="$t('order.gift.name')"
          allowClear
        >
          <a-select-option
            v-for="op in giftTypeList"
            :key="op.code"
            :value="op.code"
          >
            {{ op.name }}
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item
        slot="state"
        :label="$t('orderManagement.order.list.filters.state.label')"
      >
        <a-select
          :getPopupContainer="trigger => trigger.parentNode"
          size="large"
          v-model="form.state"
          :placeholder="
            $t('orderManagement.order.list.filters.state.placeholder')
          "
          allowClear
        >
          <a-select-option
            v-for="op in orderStateList"
            :key="op.dictCode"
            :value="op.dictCode"
          >
            {{ op.dictName }}
          </a-select-option>
        </a-select>
      </a-form-model-item>
    </ListFilter>
    <div class="gift-balance">
      <div class="balance-content">
        {{ $t("order.gift.list.balanceLabel") }}: 99{{ $t("order.gift.unit") }}
      </div>
      <a-button type="link" class="change-record" @click="onChangeRecord">
        {{ $t("order.gift.changeRecord") }}
      </a-button>
    </div>
    <ECTable
      showTitle
      :title="$t('order.gift.grantRecord')"
      :columns="tableColumns"
      :data-source="dataList"
      bordered
      :loading="loading"
      :pagination="{ total, current, pageSize }"
      @change="handleTableChange"
    >
      <div slot="tableHeaderRight" class="table-buttons">
        <a-button type="primary" @click="onGrant">
          {{ $t("order.gift.grant") }}
        </a-button>
        <a-button type="primary" @click="onExport">
          {{ $t("button.export") }}
        </a-button>
      </div>
      <!-- 操作列 -->
      <template slot="action" slot-scope="text, record">
        <div class="table-operations">
          <a-popconfirm
            :title="$t('button.confirmCancel')"
            :ok-text="$t('button.yes')"
            :cancel-text="$t('button.no')"
            @confirm="onDelete(record)"
          >
            <a-button type="link" class="table-btn">
              {{ $t("order.gift.list.cancel") }}
            </a-button>
          </a-popconfirm>
        </div>
      </template>
    </ECTable>
    <!--入职礼发放弹窗-->
    <GrantGiftModal :visible.sync="grantGiftVisible" />
    <!--入职礼记录弹窗-->
    <GrantGiftRecordModal :visible.sync="grantRecordVisible" />
  </div>
</template>

<script>
import ListFilter from "@/pages/home/components/ListFilter";
import ECTable from "@/components/ECTable";
import Vue from "vue";
import { FETCH_CODE } from "@/config";
import { getDic } from "@/api";
import GrantGiftModal
  from "@/pages/workspace/backgroundCheck/gift/Grant/Modal.vue";
import GrantGiftRecordModal
  from "@/pages/workspace/backgroundCheck/gift/Record/Modal.vue";

const tableColumns = [
  {
    title: Vue.prototype.$t("order.gift.list.name"),
    dataIndex: "name",
    width: 130,
    align: "left"
  },
  {
    title: Vue.prototype.$t("member.base.form.name"),
    dataIndex: "userName",
    width: 140,
    align: "left"
  },
  {
    title: Vue.prototype.$t("orderManagement.order.list.filters.state.label"),
    dataIndex: "stateName",
    width: 140,
    align: "center"
  },
  {
    title: Vue.prototype.$t("order.gift.list.expNum"),
    dataIndex: "expNum",
    width: 140,
    align: "left"
  },
  {
    title: Vue.prototype.$t("order.gift.list.reason"),
    dataIndex: "reason",
    width: 140,
    align: "left"
  },
  {
    title: Vue.prototype.$t("action"),
    dataIndex: "action",
    key: "action",
    align: "center",
    scopedSlots: { customRender: "action" },
    width: 90,
  }
];
export default {
  name: "GiftList",
  components: {
    GrantGiftRecordModal,
    GrantGiftModal,
    ListFilter,
    ECTable
  },
  data() {
    return {
      form: {},
      formList: [
        {
          label: this.$t("order.gift.name"),
          slotName: "type",
          type: "slot"
        },
        {
          label: this.$t("member.base.form.name"),
          key: "userName",
          type: "input"
        },
        {
          label: this.$t("orderManagement.order.list.filters.state.label"),
          slotName: "state",
          type: "slot"
        },
        {
          label: this.$t("order.gift.list.expNum"),
          key: "expNum",
          type: "input"
        },
        {
          label: this.$t("order.gift.list.createTime"),
          key: "createTime",
          type: "timeRange"
        }
      ],
      tableColumns,
      dataList: [],
      total: 0,
      current: 1,
      pageSize: 10,
      loading: false,
      giftTypeList: [
        {
          code: "1",
          name: "入职礼"
        }
      ],
      orderStateList: [],
      grantGiftVisible: false,
      grantRecordVisible: false,
    };
  },
  mounted() {
    this.getList();
    this.getOrderStateList();
  },
  methods: {
    handleTableChange() {},
    onSearch(filterForm) {
      this.current = 1;
      this.getList(filterForm);
    },
    onReset() {
      this.form = {
        province: undefined,
        city: undefined
      };
    },
    async getList(filterForm = {}) {
      if (this.loading) {
        return;
      }
      this.loading = true;
      const params = {
        ...filterForm,
        pageNum: this.current,
        pageSize: this.pageSize
      };
      const dataList = [];
      this.dataList = dataList.map(it => {
        return {
          ...it,
          key: it.id
        };
      });
      this.loading = false;
    },
    async getOrderStateList() {
      const res = await getDic({
        code: "order_state"
      });
      if (res.flag !== FETCH_CODE.SUCCESS.KEY) return;
      this.orderStateList = res.data;
    },
    onExport() {},
    onGrant() {
      this.grantGiftVisible = true;
    },
    onChangeRecord() {
      this.grantRecordVisible = true;
    }
  }
};
</script>

<style lang="less" scoped>
.table-buttons {
  & > * {
    margin-left: @ec-gutter;
  }
}
.gift-balance {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
  margin-bottom: 20px;

  .balance-content {
    flex: 1;
    background: #FFF5E6;
    border-radius: 4px 4px 4px 4px;
    border: 1px solid #FFBC36;
    font-size: 14px;
    font-weight: normal;
    padding: 10px 20px;
  }

  .change-record {
    flex-shrink: 0;
    flex-grow: 0;
  }
}
</style>
