<template>
  <a-form-model
    ref="formRef"
    :model="formData"
    :rules="rules"
    :label-col="labelCol"
    :wrapper-col="wrapperCol"
  >
    <a-row>
      <a-col :span="24">
        <a-form-model-item
          :label="$t('system.benefits.distribution.companyIds')"
          prop="companyIds"
        >
          <a-select
            :getPopupContainer="trigger => trigger.parentNode"
            mode="multiple"
            v-model="formData.companyIds"
            :placeholder="$t('system.benefits.distribution.companyIdsPlaceholder')"
            allowClear
          >
            <a-select-option
              v-for="op in companyList"
              :key="op.companyId"
              :value="op.companyId"
            >
              {{ op.name }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
      </a-col>
      <a-col :span="24">
        <a-list
          class="distribution-company-list"
          item-layout="vertical"
          :data-source="benefitsList"
        >
          <a-list-item slot="renderItem" slot-scope="item">
            <a-list-item-meta :description="getDescStr(item)">
              <div slot="title">{{ item.name }}</div>
            </a-list-item-meta>
          </a-list-item>
        </a-list>
      </a-col>
    </a-row>
  </a-form-model>
</template>

<script>
import { listCompany } from "@/pages/system/api";
import { FETCH_CODE } from "@/config";
import { showMsg } from "@/utils";
import { distributionBenefits } from "@/pages/system/benefits/distribution/api";

const Empty = {
  companyIds: [],
};

export default {
  name: "DistributionCompanyList",
  props: {
    benefitsList: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      formData: { ...Empty },
      rules: {
        companyIds: [
          { required: true, message: this.$t("system.benefits.distribution.companyIdsRequired") },
        ],
      },
      labelCol: { span: 6 },
      wrapperCol: { span: 18 },
      companyList: [],
      loading: false,
    };
  },
  methods: {
    async getCompanyList() {
      this.companyList = [];
      const res = await listCompany({
        name: "",
      });
      this.companyList = res.data || [];
    },
    init() {
      this.getCompanyList();
    },
    onValid() {
      const result = { flag: FETCH_CODE.SUCCESS.KEY, data: this.formData, msg: "" };
      return new Promise((resolve) => {
        this.$refs.formRef.validate((valid, errors) => {
          if (!valid) {
            result.flag = FETCH_CODE.WARNING.KEY;
            result.msg = Object.values(errors)[0]?.[0]?.message;
            resolve(result);
            return;
          }
          resolve(result);
        });
      });
    },
    async onSubmit() {
      if (this.loading) return false;
      this.loading = true;
      const valid = await this.onValid();
      if (valid.flag !== FETCH_CODE.SUCCESS.KEY) {
        showMsg(valid);
        this.loading = false;
        return false;
      }
      const params = {
        ...valid.data,
        ids: this.benefitsList.map((it) => it.id),
      };
      const res = await distributionBenefits(params);
      if (res.flag !== FETCH_CODE.SUCCESS.KEY) {
        showMsg(res);
        this.loading = false;
        return false;
      }
      showMsg({
        flag: FETCH_CODE.SUCCESS.KEY,
        msg: this.$t("showMsg.success.operation"),
      });
      this.loading = false;
      return true;
    },
    getDescStr(record) {
      const arr = [
        {
          label: this.$t("welfarePlat.companyBenefits.benefitsType.label"),
          value: record.benefitsTypeName,
        },
      ];
      if (record.points) {
        arr.push({
          label: this.$t("welfarePlat.autoManualRelease.list.col3", { name: this.$store.state.user.platformConf.moneyName }),
          value: record.pointsShow,
        });
      }
      if (record.grantTime) {
        arr.push({
          label: this.$t("welfarePlat.companyBenefits.grantTime.label"),
          value: record.grantTime,
        });
      }
      arr.push({
        label: this.$t("welfarePlat.companyBenefits.isAuto.label"),
        value: record.isAuto ? this.$t("button.yes") : this.$t("button.no"),
      });
      return arr.map((it) => `${it.label}: ${it.value}`).join("; ");
    },
  },
  mounted() {
    this.init();
  },
};
</script>

<style lang="less" scoped>
.distribution-company-list {
  max-height: 450px;
  overflow-x: hidden;
  overflow-y: auto;
}
</style>
