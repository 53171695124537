<template>
  <div class="dic-list">
    <div class="search-head" style="position: relative;">
      <a-select
        :getPopupContainer="trigger => trigger.parentNode"
        v-model="dictType"
        :placeholder="$t('system.dic.form.dictType.placeholder')"
        allowClear
        class="search-input"
      >
        <a-select-option
          v-for="op in dictTypeList"
          :key="op.dictCode"
          :value="op.dictCode"
        >
          {{ op.dictName }}
        </a-select-option>
      </a-select>
      <a-input
        size="large"
        v-model="searchText"
        :placeholder="$t('system.dic.search')"
        allowClear
        class="search-input"
        @keydown.enter="onSearch"
      />
      <a-button type="primary" @click="onSearch">
        {{ $t("button.filter") }}
      </a-button>
    </div>
    <ECTable
      showTitle
      :title="$t('system.dic.title')"
      :columns="columns"
      :data-source="list"
      bordered
      rowKey="id"
      key="id"
      :loading="loading"
      :pagination="{ total, current, pageSize }"
      @change="onTableChange"
    >
      <div slot="tableHeaderRight" class="table-buttons">
        <a-button type="primary" @click="onAdd">
          {{ $t("system.dic.add") }}
        </a-button>
      </div>
      <template slot="state" slot-scope="text, record">
        <a-switch :checked="record.state" />
      </template>
      <template slot="dictIcon" slot-scope="text">
        <img style="width: 30px;max-height: 60px;" :src="text" alt="" v-if="text" />
        <span v-else>--</span>
      </template>
<!--   排序   -->
      <template slot="index" slot-scope="text, record, index">
        <div>{{ index + 1 }}</div>
      </template>
      <!-- 操作列 -->
      <template slot="action" slot-scope="text, record">
        <div class="table-operations">
          <a-button type="link" class="table-btn" @click="onEdit(record)">
            <a-icon type="edit" />
            {{ $t("button.edit") }}
          </a-button>
          <a-popconfirm
            :title="$t('button.confirmDelete')"
            :ok-text="$t('button.yes')"
            :cancel-text="$t('button.no')"
            @confirm="onDelete(record)"
          >
            <a-button type="link" class="table-btn danger">
              <a-icon type="delete" />
              {{ $t("button.remove") }}
            </a-button>
          </a-popconfirm>
        </div>
      </template>
    </ECTable>
    <a-modal
      :visible="formVisible"
      @cancel="onFormCancel"
      @ok="onFormConfirm"
      class="dic-form-modal"
      :maskClosable="false"
      destroyOnClose
      :title="$t('system.dic.modal')"
    >
      <DicForm ref="formRef" :data="formRecord" />
    </a-modal>
  </div>
</template>

<script>
import ECTable from "@/components/ECTable";
import { delDic, getDicPage, saveDic } from "@/pages/system/api";
import { cloneDeep, trim } from "lodash";
import { FETCH_CODE } from "@/config";
import { showMsg } from "@/utils";
import DicForm from "@/pages/system/dic/Form.vue";
import { getDic } from "@/api";

export default {
  name: "DicList",
  components: { DicForm, ECTable },
  data() {
    return {
      searchText: "",
      dictType: undefined,
      columns: [
        {
          title: this.$t("system.dic.cols.index"),
          dataIndex: "index",
          width: 80,
          key: 'index',
          align: "center",
          scopedSlots: { customRender: "index" },
        },
        {
          title: this.$t("system.dic.cols.dictType"),
          dataIndex: "dictTypeName",
          width: 200,
          align: "left",
        },
        {
          title: this.$t("system.dic.cols.dictName"),
          dataIndex: "dictName",
          width: 200,
          align: "left",
        },
        {
          title: this.$t("system.dic.cols.dictCode"),
          dataIndex: "dictCode",
          width: 200,
          align: "left",
        },
        {
          title: this.$t("system.dic.cols.sort"),
          dataIndex: "sort",
          width: 80,
          align: "center",
        },
        {
          title: this.$t("system.dic.cols.dictIcon"),
          dataIndex: "dictIcon",
          width: 100,
          scopedSlots: { customRender: "dictIcon" },
          align: "center",
        },
        {
          title: this.$t("system.dic.cols.state"),
          dataIndex: "state",
          width: 80,
          scopedSlots: { customRender: "state" },
          align: "center",
        },
        {
          title: this.$t("table.createBy"),
          dataIndex: "createBy",
          width: 120,
          align: "left",
        },
        {
          title: this.$t("table.createTime"),
          dataIndex: "createTime",
          width: 160,
          align: "center",
        },
        {
          title: this.$t("table.updateBy"),
          dataIndex: "updateBy",
          width: 120,
          align: "left",
        },
        {
          title: this.$t("table.updateTime"),
          dataIndex: "updateTime",
          width: 160,
          align: "center",
        },
        {
          title: this.$t("system.dic.cols.remark"),
          dataIndex: "remark",
          align: "left",
          width: 200,
        },
        {
          title: this.$t("action"),
          dataIndex: "action",
          key: "action",
          align: "center",
          scopedSlots: { customRender: "action" },
          width: 130,
          fixed: "right",
        },
      ],
      list: [],
      loading: false,
      total: 0,
      current: 1,
      pageSize: 10,
      formVisible: false,
      formLoading: false,
      formRecord: {},
      dictTypeList: [],
    };
  },
  methods: {
    onSearch() {
      this.current = 1;
      this.getList();
    },
    onTableChange(page) {
      this.current = page.current;
      this.pageSize = page.pageSize;
      this.getList();
    },
    onAdd() {
      this.formVisible = true;
    },
    onEdit(record) {
      this.formRecord = cloneDeep(record);
      this.formVisible = true;
    },
    async onDelete(record) {
      if (this.loading) return;
      this.loading = true;
      const res = await delDic({ id: record.id });
      if (res.flag === FETCH_CODE.SUCCESS.KEY) {
        res.msg = this.$t("showMsg.success.delete");
      }
      showMsg(res);
      this.loading = false;
      if (res.flag === FETCH_CODE.SUCCESS.KEY) {
        this.getList();
      }
    },
    onFormCancel() {
      this.$refs.formRef.onReset();
      this.formVisible = false;
      this.formRecord = {};
    },
    async onFormConfirm() {
      if (this.formLoading) return;
      this.formLoading = true;
      const valid  = await this.$refs.formRef.onValid();
      if (valid.flag !== FETCH_CODE.SUCCESS.KEY) {
        showMsg(valid);
        this.formLoading = false;
        return;
      }
      const res = await saveDic(valid.data);
      if (res.flag !== FETCH_CODE.SUCCESS.KEY) {
        showMsg(res);
        this.formLoading = false;
        return;
      }
      let msg = this.$t("showMsg.success.add");
      if (this.formRecord.id) {
        msg = this.$t("showMsg.success.save");
      }
      showMsg({ flag: FETCH_CODE.SUCCESS.KEY, msg });
      this.formLoading = false;
      this.onFormCancel();
      this.getList();
    },
    async getList() {
      this.loading = true;
      const params = {
        pageNum: this.current,
        pageSize: this.pageSize,
        search: trim(this.searchText),
        dictType: this.dictType,
      };
      const res = await getDicPage(params);
      if (res.flag !== FETCH_CODE.SUCCESS.KEY) {
        showMsg(res);
        this.loading = false;
        return;
      }
      this.list = res.data.records || [];
      this.current = res.data.current;
      this.total = res.data.total;
      this.pageSize = res.data.size;
      this.loading = false;
    },
    async getDictTypeList() {
      const res = await getDic({ code: "dict_type" });
      this.dictTypeList = res.data;
    },
  },
  mounted() {
    this.getDictTypeList();
    this.getList();
  },
};
</script>

<style lang="less" scoped>
.search-head {
  padding: @ec-gutter20 0;

  .search-input {
    width: 300px;
    margin-right: @ec-gutter;
  }
}
</style>
