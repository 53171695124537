<template>
  <div class="role-list">
    <div class="search-head">
      <a-input
        size="large"
        v-model="searchText"
        :placeholder="$t('system.role.search')"
        allowClear
        class="search-input"
        @keydown.enter="onSearch"
      />
      <a-button type="primary" @click="onSearch">
        {{ $t("button.filter") }}
      </a-button>
    </div>
    <ECTable
      showTitle
      :title="$t('system.role.title')"
      :columns="columns"
      :data-source="list"
      bordered
      rowKey="id"
      key="id"
      :loading="loading"
      :pagination="{ total, current, pageSize }"
      @change="onTableChange"
    >
      <div slot="tableHeaderRight" class="table-buttons">
        <a-button type="primary" @click="onAdd">
          {{ $t("system.role.add") }}
        </a-button>
      </div>
      <template slot="sort" slot-scope="text, record, index">
        {{ (current - 1) * pageSize + (index + 1) }}
      </template>
      <!-- 操作列 -->
      <template slot="action" slot-scope="text, record">
        <div class="table-operations">
          <a-button type="link" class="table-btn" @click="onPermission(record)">
            <a-icon type="appstore" />
            {{ $t("button.permission") }}
          </a-button>
          <a-button type="link" class="table-btn" @click="onEdit(record)">
            <a-icon type="edit" />
            {{ $t("button.edit") }}
          </a-button>
          <a-popconfirm
            :title="$t('button.confirmDelete')"
            :ok-text="$t('button.yes')"
            :cancel-text="$t('button.no')"
            @confirm="onDelete(record)"
          >
            <a-button type="link" class="table-btn danger">
              <a-icon type="delete" />
              {{ $t("button.remove") }}
            </a-button>
          </a-popconfirm>
        </div>
      </template>
    </ECTable>
    <a-modal
      :visible="formVisible"
      @cancel="onFormCancel"
      @ok="onFormConfirm"
      class="role-form-modal"
      :maskClosable="false"
      destroyOnClose
      :title="$t('system.role.modal')"
    >
      <DicForm ref="formRef" :data="formRecord" />
    </a-modal>
    <a-modal
      :visible="menuVisible"
      @cancel="onMenuCancel"
      @ok="onMenuConfirm"
      class="role-menu-modal"
      :maskClosable="false"
      destroyOnClose
      :title="$t('system.role.permission')"
    >
      <a-spin :spinning="menuLoading">
        <div class="role-permission-tree-wrapper">
          <a-tree
            v-model="menuSelected"
            checkable
            :auto-expand-parent="true"
            :tree-data="menuData"
            class="role-permission-tree"
            checkStrictly
            @check="onMenuSelected"
          />
        </div>
      </a-spin>
    </a-modal>
  </div>
</template>

<script>
import ECTable from "@/components/ECTable";
import {
  addRole,
  delRole, getPermission, listMenu,
  pageRole, savePermission,
  updateRole
} from "@/pages/system/api";
import { cloneDeep, trim } from "lodash";
import { DateFormatFull, FETCH_CODE } from "@/config";
import { showMsg } from "@/utils";
import DicForm from "@/pages/system/role/Form.vue";
import { list2Tree, listDicFormatter, tree2List } from "@/utils/tools";
import { MenuSysType } from "@/pages/system/config";
import moment from "moment";

export default {
  name: "RoleList",
  components: { DicForm, ECTable },
  data() {
    return {
      searchText: "",
      columns: [
        {
          title: this.$t("system.role.cols.sort"),
          dataIndex: "sort",
          scopedSlots: { customRender: "sort" },
          width: 80,
          align: "center",
        },
        {
          title: this.$t("system.role.cols.name"),
          dataIndex: "name",
          width: 200,
          align: "left",
        },
        {
          title: this.$t("system.role.cols.roleId"),
          dataIndex: "roleId",
          width: 200,
          align: "left",
        },
        {
          title: this.$t("system.role.cols.sysType"),
          dataIndex: "sysTypeName",
          width: 90,
          align: "center",
        },
        {
          title: this.$t("table.createBy"),
          dataIndex: "createBy",
          width: 120,
          align: "left",
        },
        {
          title: this.$t("table.createTime"),
          dataIndex: "createTime",
          width: 160,
          align: "center",
        },
        {
          title: this.$t("table.updateBy"),
          dataIndex: "updateBy",
          width: 120,
          align: "left",
        },
        {
          title: this.$t("table.updateTime"),
          dataIndex: "updateTime",
          width: 160,
          align: "center",
        },
        {
          title: this.$t("system.role.cols.remark"),
          dataIndex: "remark",
          align: "left",
          width: 200,
        },
        {
          title: this.$t("action"),
          dataIndex: "action",
          key: "action",
          align: "center",
          scopedSlots: { customRender: "action" },
          width: 130,
          fixed: "right",
        },
      ],
      list: [],
      loading: false,
      total: 0,
      current: 1,
      pageSize: 10,
      formVisible: false,
      formLoading: false,
      formRecord: {},
      menuVisible: false,
      menuLoading: false,
      menuSelected: { checked: [], halfChecked: [] },
      menuData: [],
      menuList: [],
    };
  },
  methods: {
    onSearch() {
      this.current = 1;
      this.getList();
    },
    onTableChange(page) {
      this.current = page.current;
      this.pageSize = page.pageSize;
      this.getList();
    },
    onAdd() {
      this.formVisible = true;
    },
    onEdit(record) {
      this.formRecord = cloneDeep(record);
      this.formVisible = true;
    },
    async onDelete(record) {
      if (this.loading) return;
      this.loading = true;
      const res = await delRole({ id: record.id });
      if (res.flag === FETCH_CODE.SUCCESS.KEY) {
        res.msg = this.$t("showMsg.success.delete");
      }
      showMsg(res);
      this.loading = false;
      if (res.flag === FETCH_CODE.SUCCESS.KEY) {
        this.getList();
      }
    },
    onFormCancel() {
      this.$refs.formRef.onReset();
      this.formVisible = false;
      this.formRecord = {};
    },
    async onFormConfirm() {
      if (this.formLoading) return;
      this.formLoading = true;
      const valid  = await this.$refs.formRef.onValid();
      if (valid.flag !== FETCH_CODE.SUCCESS.KEY) {
        showMsg(valid);
        this.formLoading = false;
        return;
      }
      if (this.formRecord.id) {
        const res = await updateRole(valid.data);
        if (res.flag !== FETCH_CODE.SUCCESS.KEY) {
          showMsg(res);
          this.formLoading = false;
          return;
        }
        showMsg({
          flag: FETCH_CODE.SUCCESS.KEY,
          msg: this.$t("showMsg.success.save"),
        });
      } else {
        const res = await addRole(valid.data);
        if (res.flag !== FETCH_CODE.SUCCESS.KEY) {
          showMsg(res);
          this.formLoading = false;
          return;
        }
        showMsg({
          flag: FETCH_CODE.SUCCESS.KEY,
          msg: this.$t("showMsg.success.add"),
        });
      }

      this.formLoading = false;
      this.onFormCancel();
      this.getList();
    },
    async getList() {
      this.loading = true;
      const params = {
        pageNum: this.current,
        pageSize: this.pageSize,
        search: trim(this.searchText),
      };
      const res = await pageRole(params);
      if (res.flag !== FETCH_CODE.SUCCESS.KEY) {
        showMsg(res);
        this.loading = false;
        return;
      }
      const list = res.data.records || [];
      list.forEach((it) => {
        it.createTime = it.createTime ? moment(it.createTime).format(DateFormatFull) : "--";
        it.updateTime = it.updateTime ? moment(it.updateTime).format(DateFormatFull) : "--";
      });
      this.list = await listDicFormatter(list, [
        { code: MenuSysType, key: "sysType", local: true },
      ]);
      this.current = res.data.current;
      this.total = res.data.total;
      this.pageSize = res.data.size;
      this.loading = false;
    },
    async onPermission(record) {
      this.menuLoading = true;
      const res = await getPermission({ roleId: record.roleId });
      if (res.flag !== FETCH_CODE.SUCCESS.KEY) {
        showMsg(res);
        return;
      }
      const selected = res.data || [];
      const _menuSelected = [];
      selected.forEach((it) => {
        _menuSelected.push(it.permissionId);
        if (it.useList) {
          it.useList.forEach((_it) => {
            _menuSelected.push(`btn-${it.permissionId}-${_it.key}`);
          });
        }
      });
      this.menuSelected = { checked: _menuSelected };
      this.formRecord = record;
      this.menuVisible = true;
      await this.getMenuList();
      this.menuLoading = false;
    },
    onMenuCancel() {
      this.menuVisible = false;
      this.menuLoading = false;
      this.formRecord = {};
    },
    async onMenuConfirm() {
      if (this.menuLoading) return;
      this.menuLoading = true;
      const selectedBtn = {};
      const selectedMenu = [];
      this.menuSelected.checked.forEach((key) => {
        if (/^btn-/.test(key)) {
          const ls = key.split("-");
          if (!selectedBtn[ls[1]]) {
            selectedBtn[ls[1]] = [ls[2]];
            return;
          }
          selectedBtn[ls[1]].push(ls[2]);
          return;
        }
        selectedMenu.push(key);
      });
      Object.keys(selectedBtn).forEach((key) => {
        if (!selectedMenu.includes(key)) {
          selectedMenu.push(key);
        }
      });

      const params = {
        permissionList: selectedMenu.map((id) => {
          const obj = {
            permissionId: id,
            permissionList: selectedBtn[id] || [],
          };
          return obj;
        }),
        roleId: this.formRecord.roleId,
      };
      const res = await savePermission(params);
      if (res.flag !== FETCH_CODE.SUCCESS.KEY) {
        showMsg(res);
        this.menuLoading = false;
        return;
      }
      this.onMenuCancel();
      this.menuLoading = false;
    },
    async getMenuList() {
      this.menuData = [];
      const res = await listMenu();
      if (res.flag !== FETCH_CODE.SUCCESS.KEY) {
        return;
      }
      const list = tree2List(res.data, {
        childrenName: "child",
      });
      const btnData = [];
      const menuData = list.sort((a, b) => a.sort - b.sort).map((it) => {
        if (it.useList) {
          it.useList.forEach((_it) => {
            const btnKey = "btn-" + it.permissionId + "-" + _it.key;
            btnData.push({
              key: btnKey,
              permissionId: btnKey,
              parentId: it.permissionId,
              title: _it.name,
              type: "btn",
            });
          });
        }
        return {
          ...it,
          key: it.permissionId,
          title: it.name,
        };
      });
      this.menuList = [...menuData, ...btnData];
      this.menuData = list2Tree([...menuData, ...btnData], {
        keyName: "permissionId",
        parentKeyName: "parentId",
      });
    },
    onMenuSelected(checkedKeys, options) {
      const currId = options.node.eventKey;
      const currItem = this.menuList.find((it) => it.key === currId);

      checkedKeys.checked.forEach((key) => {
        const curr = this.menuList.find((it) => it.key === key);
        if (!curr) return;
        // 当前勾选
        if (options.checked) {
          // 向上查找是否都勾选了 没有勾选的自动勾选
          const parentIds = [];
          this.findParent(curr.parentId, parentIds);
          parentIds.forEach((it) => {
            if (!checkedKeys.checked.includes(it)) {
              checkedKeys.checked.push(it);
            }
          });
          // 向下查找并全部自动勾选
          // 和当前勾选平级的除外
          const childIds = [];
          this.findChild(key, childIds, currItem?.parentId);
          childIds.forEach((it) => {
            if (!checkedKeys.checked.includes(it)) {
              checkedKeys.checked.push(it);
            }
          });
        }
        // 当取消勾选时 向下查找是否有勾选的 有勾选自动取消
        else {
          // 取消勾选联动其子集
          for (let i = checkedKeys.checked.length - 1; i >= 0; i--) {
            const key = checkedKeys.checked[i];
            const curr = this.menuList.find((it) => it.key === key);
            if (!curr || !curr.parentId) continue;
            if (!checkedKeys.checked.includes(curr.parentId)) {
              checkedKeys.checked.splice(i, 1);
            }
          }
        }
      });

      // 当前平级的都不勾选时 父级取消勾选
      if (!options.checked) {
        const equalIds = [];
        for (const key of checkedKeys.checked) {
          const menu = this.menuList.find((it) => it.key === key);
          if (!menu) continue;
          if (menu.parentId === currItem?.parentId && menu.key !== currId) {
            equalIds.push(menu.key);
          }
        }
        if (!equalIds.length) {
          const idx = checkedKeys.checked.indexOf(currItem?.parentId);
          if (idx > -1) {
            checkedKeys.checked.splice(idx, 1);
          }
        }
      }
      this.menuSelected = { checked: checkedKeys.checked };
    },
    findParent(parentId, parentIds = []) {
      if (!parentId) return;
      const curr = this.menuList.find((it) => it.key === parentId);
      if (!curr) return;
      parentIds.push(curr.key);
      this.findParent(curr.parentId, parentIds);
    },
    findChild(key, childIds = [], currParentId) {
      if (!key) return;
      for (const menu of this.menuList) {
        if (menu.parentId === key && menu.parentId !== currParentId) {
          childIds.push(menu.key);
          this.findChild(menu.key, childIds, currParentId);
        }
      }
    },
  },
  mounted() {
    this.getList();
  },
};
</script>

<style lang="less" scoped>
.search-head {
  padding: @ec-gutter20 0;

  .search-input {
    width: 300px;
    margin-right: @ec-gutter;
  }
}
.role-permission-tree-wrapper {
  max-height: 500px;
  min-height: 270px;
  overflow: auto;
}
</style>
