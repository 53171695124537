<template>
  <div class="grant-join-gift">
    <a-table
      :columns="columns"
      :data-source="list"
      bordered
      :pagination="{ current, pageSize, total }"
      @change="onPage"
    >
    </a-table>
  </div>
</template>

<script>
export default {
  name: "GrantGiftRecord",
  data() {
    return {
      columns: [
        {
          title: this.$t("order.gift.name"),
          dataIndex: "name",
          key: "name",
          align: "left"
        },
        {
          title: this.$t("order.gift.record.value"),
          dataIndex: "value",
          key: "value",
          align: "right"
        },
        {
          title: this.$t("order.dataForm.type"),
          dataIndex: "type",
          key: "type",
          align: "center"
        },
        {
          title: this.$t("order.gift.record.last"),
          dataIndex: "last",
          key: "last",
          align: "right"
        },
        {
          title: this.$t("orderManagement.order.detail.operatingInfo.cols.aaa2"),
          dataIndex: "createTime",
          key: "createTime",
          align: "center"
        },
      ],
      list: [],
      current: 1,
      pageSize: 10,
      total: 0,
    };
  },
  methods: {
    getList() {
      this.list = [...new Array(10).keys()].map((k) => ({
        key: "1" + k,
        name: "北京奇思妙想公司入职礼" + k,
        value: "-1000" + k,
        type: "发放" + k,
        last: "12343" + k,
        createTime: "2023-05-21 13:13:13",
      }));
      this.total = 98;
    },
    onPage({ current }) {
      this.current = current;
      this.getList();
    },
  },
  mounted() {
    this.getList();
  },
}
</script>

<style lang="less" scoped></style>
