<template>
  <a-form-model
    ref="formRef"
    :model="form"
    :rules="rules"
    :label-col="labelCol"
    :wrapper-col="wrapperCol"
  >
    <a-row>
      <a-col :span="24">
        <a-form-model-item
          :label="$t('system.menu.form.name.label')"
          prop="name"
        >
          <a-input
            v-model="form.name"
            :placeholder="$t('system.menu.form.name.placeholder')"
          />
        </a-form-model-item>
      </a-col>
      <a-col :span="24">
        <a-form-model-item
          :label="$t('system.menu.form.parentId.label')"
          prop="parentId"
        >
          <a-select
            :getPopupContainer="trigger => trigger.parentNode"
            v-model="form.parentId"
            :placeholder="$t('system.menu.form.parentId.placeholder')"
            allowClear
            @change="onParentIdChange"
          >
            <a-select-option
              v-for="op in menuList"
              :key="op.permissionId"
              :value="op.permissionId"
            >
              {{ op.name }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
      </a-col>
      <a-col :span="24">
        <a-form-model-item
          :label="$t('system.menu.form.type.label')"
          prop="type"
        >
          <a-select
            :getPopupContainer="trigger => trigger.parentNode"
            v-model="form.type"
            :placeholder="$t('system.menu.form.type.placeholder')"
            :disabled="!form.parentId"
          >
            <a-select-option
              v-for="op in typeList"
              :key="op.dictCode"
              :value="op.dictCode"
            >
              {{ op.dictName }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
      </a-col>
      <a-col :span="24">
        <a-form-model-item
          :label="$t('system.menu.form.href.label')"
          prop="href"
        >
          <a-input
            v-model="form.href"
            :placeholder="$t('system.menu.form.href.placeholder')"
          />
        </a-form-model-item>
      </a-col>
      <a-col :span="24">
        <a-form-model-item
          :label="$t('system.menu.form.sysType.label')"
          prop="sysType"
        >
          <a-select
            :getPopupContainer="trigger => trigger.parentNode"
            v-model="form.sysType"
            :placeholder="$t('system.menu.form.sysType.placeholder')"
            allowClear
            :disabled="form.parentId"
          >
            <a-select-option
              v-for="op in sysTypeList"
              :key="op.dictCode"
              :value="op.dictCode"
            >
              {{ op.dictName }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
      </a-col>
      <a-col :span="24">
        <a-form-model-item
          :label="$t('system.menu.form.sort.label')"
          prop="sort"
        >
          <a-input-number
            v-model="form.sort"
            :max="99999999"
            :placeholder="$t('system.menu.form.sort.placeholder')"
          />
        </a-form-model-item>
      </a-col>
    </a-row>
  </a-form-model>
</template>

<script>
import { FETCH_CODE } from "@/config";
import { MenuSysType, MenuType } from "@/pages/system/config";
import { listMenu } from "@/pages/system/api";

const formEmpty = {
  name: null,
  parentId: undefined,
  href: null,
  sysType: undefined,
  type: MenuType[0].dictCode,
  sort: null,
};

export default {
  name: "MenuForm",
  props: {
    data: Object,
  },
  data() {
    return {
      form: {
        ...formEmpty,
      },
      rules: {
        name: [
          { required: true, message: this.$t("system.menu.form.name.required") },
          { max: 100, message: this.$t("system.menu.form.name.max", { length: 100 }) },
        ],
        href: [
          { max: 500, message: this.$t("system.menu.form.href.max", { length: 500 }) },
        ],
        sort: [
          { required: true, message: this.$t("system.menu.form.sort.required") },
          { type: "number", message: this.$t("system.menu.form.sort.number") },
        ],
        sysType: [
          { required: true, message: this.$t("system.menu.form.sysType.required") },
        ],
        type: [
          { required: true, message: this.$t("system.menu.form.type.required") },
        ],
      },
      labelCol: { span: 6 },
      wrapperCol: { span: 18 },
      sysTypeList: MenuSysType,
      typeList: MenuType,
      menuList: [],
    };
  },
  methods: {
    onValid() {
      const result = {
        flag: FETCH_CODE.SUCCESS.KEY,
        data: {
          ...this.form,
          createBy: undefined,
          createTime: undefined,
          updateBy: undefined,
          updateTime: undefined,
          child: undefined,
          children: undefined,
          useList: undefined,
        },
        msg: "",
      };
      return new Promise((resolve) => {
        this.$refs.formRef.validate((valid, errors) => {
          if (!valid) {
            result.flag = FETCH_CODE.WARNING.KEY;
            result.msg = Object.values(errors)[0]?.[0]?.message;
            resolve(result);
            return;
          }
          resolve(result);
        });
      });
    },
    onReset() {
      this.$refs.formRef.resetFields();
      this.form = {
        ...this.form,
        ...formEmpty,
      };
    },
    onInit() {
      this.getMenuList();
      if (this.data && this.data.id) {
        this.form = {
          ...this.data,
          parentId: this.data.parentId ? this.data.parentId : undefined,
        };
      }
    },
    onParentIdChange(value) {
      if (!value) return;
      const curr = this.menuList.find((it) => it.permissionId === value);
      if (!curr) return;
      this.form.sysType = curr.sysType;
    },
    async getMenuList() {
      this.menuList = [];
      const res = await listMenu();
      if (res.flag !== FETCH_CODE.SUCCESS.KEY) {
        return;
      }
      this.menuList = res.data;
    },
  },
  mounted() {
    this.onInit();
  },
};
</script>

<style lang="less" scoped></style>
