<template>
  <a-modal
    :title="$t('order.gift.changeRecord')"
    v-model="visibleInner"
    class="grant-join-gift-record-modal"
    :width="650"
    :maskClosable="false"
    destroyOnClose
  >
    <template slot="footer">
      <a-button type="primary" @click="onConfirm">
        {{ $t("button.confirm") }}
      </a-button>
    </template>
    <Index ref="indexRef" />
  </a-modal>
</template>

<script>
import Index from "./Index.vue";

export default {
  name: "GrantGiftRecordModal",
  components: { Index },
  props: { visible: Boolean },
  data() {
    return {};
  },
  computed: {
    visibleInner: {
      get() {
        return this.visible;
      },
      set(visible) {
        this.$emit("update:visible", visible);
      }
    }
  },
  methods: {
    onConfirm() {
      this.$emit("update:visible", false);
    }
  }
};
</script>

<style lang="less"></style>
