<template>
  <div class="user-list">
    <div class="search-head" style="position: relative;">
      <a-input
        size="large"
        v-model="searchText"
        :placeholder="$t('system.user.search')"
        allowClear
        class="search-input"
        @keydown.enter="onSearch"
      />
      <a-select
        :getPopupContainer="trigger => trigger.parentNode"
          v-model="userType"
          :placeholder="$t('system.user.form.userType.label')"
          class="search-input"
      >
        <a-select-option
            v-for="op in userTypeList"
            :key="op.dictCode"
            :value="op.dictCode"
        >
          {{ op.dictName }}
        </a-select-option>
      </a-select>
      <a-button type="primary" @click="onSearch">
        {{ $t("button.filter") }}
      </a-button>
    </div>
    <ECTable
      showTitle
      :title="$t('system.user.title')"
      :columns="columns"
      :data-source="list"
      bordered
      rowKey="id"
      key="id"
      :loading="loading"
      :pagination="{ total, current, pageSize }"
      @change="onTableChange"
    >
      <div slot="tableHeaderRight" class="table-buttons">
        <a-button type="primary" @click="onAdd">
          {{ $t("system.user.add") }}
        </a-button>
      </div>
      <template slot="sort" slot-scope="text, record, index">
        {{ (current - 1) * pageSize + (index + 1) }}
      </template>
      <template slot="state" slot-scope="text, record">
        <a-switch :checked="record.state" />
      </template>
      <!-- 操作列 -->
      <template slot="action" slot-scope="text, record">
        <div class="table-operations">
          <a-button
            type="link"
            class="table-btn warning"
            @click="onResetPwd(record)"
          >
            <a-icon type="redo" />
            {{ $t("button.resetPwd") }}
          </a-button>
          <a-button type="link" class="table-btn" @click="onEdit(record)">
            <a-icon type="edit" />
            {{ $t("button.edit") }}
          </a-button>
        </div>
      </template>
    </ECTable>
    <a-modal
      :visible="formVisible"
      @cancel="onFormCancel"
      @ok="onFormConfirm"
      class="user-form-modal"
      :maskClosable="false"
      destroyOnClose
      :title="$t('system.user.modal')"
    >
      <DicForm ref="formRef" :data="formRecord" />
    </a-modal>
    <ResetPwdModal
      :user="formRecord"
      :visible.sync="resetPwdVisible"
    />
  </div>
</template>

<script>
import ECTable from "@/components/ECTable";
import {
  addUser, getByUserId,
  pageUser,
  updateUser
} from "@/pages/system/api";
import { cloneDeep, trim } from "lodash";
import { FETCH_CODE } from "@/config";
import { showMsg } from "@/utils";
import DicForm from "@/pages/system/user/Form.vue";
import { UserSex, UserType } from "@/pages/system/config";
import { listDicFormatter, listPointsShowFormatter } from "@/utils/tools";
import ResetPwdModal from "@/pages/system/user/ResetPwd/Modal.vue";

export default {
  name: "UserList",
  components: { ResetPwdModal, DicForm, ECTable },
  data() {
    return {
      searchText: "",
      userType: UserType[0].dictCode,
      userTypeList: UserType,
      columns: [
        {
          title: this.$t("system.user.cols.sort"),
          dataIndex: "sort",
          width: 80,
          scopedSlots: { customRender: "sort" },
          align: "center",
        },
        {
          title: this.$t("system.user.cols.name"),
          dataIndex: "name",
          width: 120,
          align: "left",
        },
        {
          title: this.$t("system.user.cols.deptId"),
          dataIndex: "deptId",
          width: 150,
          align: "left",
        },
        {
          title: this.$t("system.user.cols.mobilePhoneNumber"),
          dataIndex: "mobilePhoneNumber",
          width: 200,
          align: "left",
        },
        {
          title: this.$t("system.user.cols.gender"),
          dataIndex: "genderName",
          width: 100,
          align: "center",
        },
        {
          title: this.$t("system.user.cols.emailAddress"),
          dataIndex: "emailAddress",
          width: 150,
          align: "left",
        },
        {
          title: this.$t("system.user.cols.points"),
          dataIndex: "pointsShow",
          align: "right",
          width: 100,
        },
        {
          title: this.$t("table.createBy"),
          dataIndex: "createBy",
          width: 120,
          align: "left",
        },
        {
          title: this.$t("table.createTime"),
          dataIndex: "createTime",
          width: 160,
          align: "center",
        },
        {
          title: this.$t("table.updateBy"),
          dataIndex: "updateBy",
          width: 120,
          align: "left",
        },
        {
          title: this.$t("table.updateTime"),
          dataIndex: "updateTime",
          width: 160,
          align: "center",
        },
        {
          title: this.$t("action"),
          dataIndex: "action",
          key: "action",
          align: "center",
          scopedSlots: { customRender: "action" },
          width: 130,
          fixed: "right",
        },
      ],
      list: [],
      loading: false,
      total: 0,
      current: 1,
      pageSize: 10,
      formVisible: false,
      formLoading: false,
      formRecord: {},
      resetPwdVisible: false,
    };
  },
  methods: {
    onSearch() {
      this.current = 1;
      this.getList();
    },
    onTableChange(page) {
      this.current = page.current;
      this.pageSize = page.pageSize;
      this.getList();
    },
    onAdd() {
      this.formVisible = true;
    },
    async onEdit(record) {
      const res = await getByUserId({
        userId: record.userId,
      });
      if (res.flag !== FETCH_CODE.SUCCESS.KEY) {
        showMsg(res);
        return;
      }
      res.data.userType = res.data.type;
      this.formRecord = res.data;
      this.formVisible = true;
    },
    async onResetPwd(record) {
      this.formRecord = cloneDeep(record);
      this.resetPwdVisible = true;
    },
    onFormCancel() {
      this.$refs.formRef.onReset();
      this.formVisible = false;
      this.formRecord = {};
    },
    async onFormConfirm() {
      if (this.formLoading) return;
      this.formLoading = true;
      const valid  = await this.$refs.formRef.onValid();
      if (valid.flag !== FETCH_CODE.SUCCESS.KEY) {
        showMsg(valid);
        this.formLoading = false;
        return;
      }
      if (this.formRecord.id) {
        const res = await updateUser(valid.data);
        if (res.flag !== FETCH_CODE.SUCCESS.KEY) {
          showMsg(res);
          this.formLoading = false;
          return;
        }
        showMsg({
          flag: FETCH_CODE.SUCCESS.KEY,
          msg:this.$t("showMsg.success.save"),
        });
      } else {
        const res = await addUser(valid.data);
        if (res.flag !== FETCH_CODE.SUCCESS.KEY) {
          showMsg(res);
          this.formLoading = false;
          return;
        }
        showMsg({
          flag: FETCH_CODE.SUCCESS.KEY,
          msg:this.$t("showMsg.success.add"),
        });
      }

      this.formLoading = false;
      this.onFormCancel();
      this.getList();
    },
    async getList() {
      this.loading = true;
      const params = {
        pageNum: this.current,
        pageSize: this.pageSize,
        userName: trim(this.searchText),
        type: this.userType,
      };
      const res = await pageUser(params);
      if (res.flag !== FETCH_CODE.SUCCESS.KEY) {
        showMsg(res);
        this.loading = false;
        return;
      }
      let list = res.data.records || [];
      list.forEach((it) => {
        it.userType = it.type;
      })
      list = listPointsShowFormatter(list, ["points"]);
      this.list = await listDicFormatter(list, [
        { code: UserSex, key: "gender", local: true },
      ]);
      this.current = res.data.current;
      this.total = res.data.total;
      this.pageSize = res.data.size;
      this.loading = false;
    },
  },
  mounted() {
    this.getList();
  },
};
</script>

<style lang="less" scoped>
.search-head {
  padding: @ec-gutter20 0;

  .search-input {
    width: 200px;
    margin-right: @ec-gutter;
  }
}
</style>
