<template>
  <a-form-model
    ref="formRef"
    :model="form"
    :rules="rules"
    :label-col="labelCol"
    :wrapper-col="wrapperCol"
  >
    <a-row>
      <a-col :span="24">
        <a-form-model-item
          :label="$t('system.role.form.name.label')"
          prop="name"
        >
          <a-input
            v-model="form.name"
            :placeholder="$t('system.role.form.name.placeholder')"
          />
        </a-form-model-item>
      </a-col>
      <a-col :span="24">
        <a-form-model-item
          :label="$t('system.role.form.roleId.label')"
          prop="roleId"
        >
          <a-input
            v-model="form.roleId"
            :placeholder="$t('form.auto')"
            disabled
          />
        </a-form-model-item>
      </a-col>
      <a-col :span="24">
        <a-form-model-item
          :label="$t('system.role.form.sysType.label')"
          prop="sysType"
        >
          <a-select
            :getPopupContainer="trigger => trigger.parentNode"
            v-model="form.sysType"
            :placeholder="$t('system.role.form.sysType.placeholder')"
            allowClear
          >
            <a-select-option
              v-for="op in sysTypeList"
              :key="op.dictCode"
              :value="op.dictCode"
            >
              {{ op.dictName }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
      </a-col>
      <a-col :span="24">
        <a-form-model-item
          :label="$t('system.role.form.remark.label')"
          prop="remark"
        >
          <a-textarea
            v-model="form.remark"
            :auto-size="{ minRows: 3, maxRows: 5 }"
            :placeholder="$t('system.role.form.remark.placeholder')"
          />
        </a-form-model-item>
      </a-col>
    </a-row>
  </a-form-model>
</template>

<script>
import { FETCH_CODE } from "@/config";
import { MenuSysType } from "@/pages/system/config";

const formEmpty = {
  name: null,
  roleId: null,
  sysType: undefined,
  remark: null,
};

export default {
  name: "RoleForm",
  props: {
    data: Object,
  },
  data() {
    return {
      form: {
        ...formEmpty,
      },
      rules: {
        name: [
          { required: true, message: this.$t("system.role.form.name.required") },
          { max: 100, message: this.$t("system.role.form.name.max", { length: 100 }) },
        ],
        sysType: [
          { required: true, message: this.$t("system.role.form.sysType.required") },
        ],
        remark: [
          { max: 500, message: this.$t("system.role.form.remark.max", { length: 500 }) },
        ],
      },
      labelCol: { span: 6 },
      wrapperCol: { span: 18 },
      sysTypeList: MenuSysType,
    };
  },
  methods: {
    onValid() {
      const result = { flag: FETCH_CODE.SUCCESS.KEY, data: this.form, msg: "" };
      return new Promise((resolve) => {
        this.$refs.formRef.validate((valid, errors) => {
          if (!valid) {
            result.flag = FETCH_CODE.WARNING.KEY;
            result.msg = Object.values(errors)[0]?.[0]?.message;
            resolve(result);
            return;
          }
          resolve(result);
        });
      });
    },
    onReset() {
      this.$refs.formRef.resetFields();
      this.form = {
        ...this.form,
        ...formEmpty,
      };
    },
    onInit() {
      if (this.data && this.data.id) {
        this.form = this.data;
      }
    },
  },
  mounted() {
    this.onInit();
  },
};
</script>

<style lang="less" scoped></style>
