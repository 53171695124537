<template>
  <div class="menu-list">
    <ECTable
      showTitle
      :title="$t('system.menu.title')"
      :columns="columns"
      :data-source="list"
      bordered
      rowKey="id"
      key="id"
      :loading="loading"
      :pagination="false"
      @change="onTableChange"
    >
      <div slot="tableHeaderRight" class="table-buttons">
        <a-button type="primary" @click="onAdd">
          {{ $t("system.menu.add") }}
        </a-button>
      </div>
      <template slot="state" slot-scope="text, record">
        <a-switch :checked="record.state" />
      </template>
      <template slot="dictIcon" slot-scope="text">
        <img style="width: 30px;max-height: 60px;" :src="text" alt="" v-if="text" />
        <span v-else>--</span>
      </template>
      <!-- 操作列 -->
      <template slot="action" slot-scope="text, record">
        <div class="table-operations">
          <a-button type="link" class="table-btn" @click="onEdit(record)">
            <a-icon type="edit" />
            {{ $t("button.edit") }}
          </a-button>
          <a-popconfirm
            :title="$t('button.confirmDelete')"
            :ok-text="$t('button.yes')"
            :cancel-text="$t('button.no')"
            @confirm="onDelete(record)"
          >
            <a-button type="link" class="table-btn danger">
              <a-icon type="delete" />
              {{ $t("button.remove") }}
            </a-button>
          </a-popconfirm>
        </div>
      </template>
    </ECTable>
    <a-modal
      :visible="formVisible"
      @cancel="onFormCancel"
      @ok="onFormConfirm"
      class="menu-form-modal"
      :maskClosable="false"
      destroyOnClose
      :title="$t('system.menu.modal')"
    >
      <DicForm ref="formRef" :data="formRecord" />
    </a-modal>
  </div>
</template>

<script>
import ECTable from "@/components/ECTable";
import {
  addMenu,
  delMenu, listMenu,
  pageMenu,
  updateMenu
} from "@/pages/system/api";
import { cloneDeep, trim } from "lodash";
import { FETCH_CODE } from "@/config";
import { showMsg } from "@/utils";
import DicForm from "@/pages/system/menu/Form.vue";
import { list2Tree, listDicFormatter, tree2List } from "@/utils/tools";
import { MenuSysType, MenuType } from "@/pages/system/config";

export default {
  name: "MenuList",
  components: { DicForm, ECTable },
  data() {
    return {
      searchText: "",
      columns: [
        {
          title: this.$t("system.menu.cols.sort"),
          dataIndex: "sort",
          width: 80,
          align: "right",
        },
        {
          title: this.$t("system.menu.cols.name"),
          dataIndex: "name",
          align: "left",
          width: 200,
        },
        {
          title: this.$t("system.menu.cols.href"),
          dataIndex: "href",
          width: 200,
          align: "left",
        },
        {
          title: this.$t("system.menu.cols.sysType"),
          dataIndex: "sysTypeName",
          width: 140,
          align: "center",
        },
        {
          title: this.$t("table.createBy"),
          dataIndex: "createBy",
          width: 120,
          align: "left",
        },
        {
          title: this.$t("table.createTime"),
          dataIndex: "createTime",
          width: 160,
          align: "center",
        },
        {
          title: this.$t("table.updateBy"),
          dataIndex: "updateBy",
          width: 120,
          align: "left",
        },
        {
          title: this.$t("table.updateTime"),
          dataIndex: "updateTime",
          width: 160,
          align: "center",
        },
        {
          title: this.$t("action"),
          dataIndex: "action",
          key: "action",
          align: "center",
          scopedSlots: { customRender: "action" },
          width: 130,
          fixed: "right",
        },
      ],
      list: [],
      loading: false,
      formVisible: false,
      formLoading: false,
      formRecord: {},
    };
  },
  methods: {
    onSearch() {
      this.current = 1;
      this.getList();
    },
    onTableChange() {
      this.getList();
    },
    onAdd() {
      this.formVisible = true;
    },
    onEdit(record) {
      this.formRecord = {
        ...record,
        type: MenuType[0].dictCode,
      };
      this.formVisible = true;
    },
    async onDelete(record) {
      if (this.loading) return;
      this.loading = true;
      const res = await delMenu({ id: record.id });
      if (res.flag === FETCH_CODE.SUCCESS.KEY) {
        res.msg = this.$t("showMsg.success.delete");
      }
      showMsg(res);
      this.loading = false;
      if (res.flag === FETCH_CODE.SUCCESS.KEY) {
        this.getList();
      }
    },
    onFormCancel() {
      this.$refs.formRef.onReset();
      this.formVisible = false;
      this.formRecord = {};
    },
    async onFormConfirm() {
      if (this.formLoading) return;
      this.formLoading = true;
      const valid  = await this.$refs.formRef.onValid();
      if (valid.flag !== FETCH_CODE.SUCCESS.KEY) {
        showMsg(valid);
        this.formLoading = false;
        return;
      }

      if (this.formRecord.id) {
        const res = await updateMenu(valid.data);
        if (res.flag !== FETCH_CODE.SUCCESS.KEY) {
          showMsg(res);
          this.formLoading = false;
          return;
        }
        showMsg({
          flag: FETCH_CODE.SUCCESS.KEY,
          msg: this.$t("showMsg.success.save"),
        });
      } else {
        const res = await addMenu(valid.data);
        if (res.flag !== FETCH_CODE.SUCCESS.KEY) {
          showMsg(res);
          this.formLoading = false;
          return;
        }
        showMsg({
          flag: FETCH_CODE.SUCCESS.KEY,
          msg: this.$t("showMsg.success.add"),
        });
      }

      this.formLoading = false;
      this.onFormCancel();
      this.getList();
    },
    async getList() {
      this.loading = true;
      const params = {};
      const res = await listMenu(params);
      if (res.flag !== FETCH_CODE.SUCCESS.KEY) {
        showMsg(res);
        this.loading = false;
        return;
      }
      const records = res.data || [];
      let list = tree2List(records, {
        childrenName: "child",
      });
      list = list.sort((a, b) => a.sort - b.sort).map((it) => {
        return {
          ...it,
          key: it.id,
        };
      });
      const list2 = await listDicFormatter(list, [
        { code: MenuSysType, key: "sysType", local: true },
      ]);
      this.list = list2Tree(list2, {
        keyName: "permissionId",
        parentKeyName: "parentId",
        childrenName: "children",
      });
      this.loading = false;
    },
  },
  mounted() {
    this.getList();
  },
};
</script>

<style lang="less" scoped>
.search-head {
  padding: @ec-gutter20 0;

  .search-input {
    width: 300px;
    margin-right: @ec-gutter;
  }
}
</style>
