<template>
  <a-form-model
    ref="formRef"
    :model="form"
    :rules="rules"
    :label-col="formItemLayout.labelCol"
    :wrapper-col="formItemLayout.wrapperCol"
  >
    <a-row>
      <a-col :span="24">
        <a-form-model-item
          :label="$t('system.conf.form.name.label')"
          prop="name"
        >
          <a-input
            v-model="form.name"
            :placeholder="$t('system.conf.form.name.placeholder')"
          />
        </a-form-model-item>
      </a-col>
      <a-col :span="24">
        <a-form-model-item
          :label="$t('system.conf.form.code.label')"
          prop="code"
        >
          <a-input
            v-model="form.code"
            :placeholder="$t('system.conf.form.code.placeholder')"
          />
        </a-form-model-item>
      </a-col>
      <a-col :span="24">
        <a-form-model-item
          :label="$t('system.conf.form.value.label')"
          prop="value"
        >
          <a-input
            v-model="form.value"
            :placeholder="$t('system.conf.form.value.placeholder')"
          />
        </a-form-model-item>
      </a-col>
      <a-col :span="24">
        <a-form-model-item
          :label="$t('system.conf.form.sort.label')"
          prop="sort"
        >
          <a-input-number
            v-model="form.sort"
            :max="99999999"
            :placeholder="$t('system.conf.form.sort.placeholder')"
          />
        </a-form-model-item>
      </a-col>
      <a-col :span="24">
        <a-form-model-item
          :label="$t('system.conf.form.state.label')"
          prop="isEncryption"
        >
          <a-switch v-model:checked="form.state" />
        </a-form-model-item>
      </a-col>
      <a-col :span="24">
        <a-form-model-item
          :label="$t('system.conf.form.isEncryption.label')"
          prop="isEncryption"
        >
          <a-switch v-model:checked="form.isEncryption" />
        </a-form-model-item>
      </a-col>
      <a-col :span="24">
        <a-form-model-item
          :label="$t('system.conf.form.remark.label')"
          prop="remark"
        >
          <a-textarea
            v-model="form.remark"
            :auto-size="{ minRows: 3, maxRows: 5 }"
            :placeholder="$t('system.conf.form.remark.placeholder')"
          />
        </a-form-model-item>
      </a-col>
    </a-row>
  </a-form-model>
</template>

<script>
import { FETCH_CODE } from "@/config";
import { langMixin } from "@/mixins";

const formEmpty = {
  code: null,
  name: null,
  value: null,
  sort: null,
  isEncryption: true,
  state: true,
  remark: null,
};

export default {
  name: "ConfigForm",
  props: {
    data: Object,
  },
  mixins: [langMixin],
  data() {
    return {
      form: {
        ...formEmpty,
      },
      rules: {
        code: [
          { required: true, message: this.$t("system.conf.form.code.required") },
          { max: 100, message: this.$t("system.conf.form.code.max", { length: 100 }) },
        ],
        name: [
          { required: true, message: this.$t("system.conf.form.name.required") },
          { max: 100, message: this.$t("system.conf.form.name.max", { length: 100 }) },
        ],
        value: [
          { required: true, message: this.$t("system.conf.form.value.required") },
          { max: 100, message: this.$t("system.conf.form.value.max", { length: 100 }) },
        ],
        sort: [
          { required: true, message: this.$t("system.conf.form.sort.required") },
          { type: "number", message: this.$t("system.conf.form.sort.number") },
        ],
        state: [
          { required: true, message: this.$t("system.conf.form.state.required") },
        ],
        isEncryption: [
          { required: true, message: this.$t("system.conf.form.isEncryption.required") },
        ],
        remark: [
          { max: 500, message: this.$t("system.conf.form.remark.max", { length: 500 }) },
        ],
      },
      fileList: [],
    };
  },
  computed: {
    formItemLayout() {
      return this.calcFormItemLayout([6, 8]);
    }
  },
  methods: {
    onFileUploadChange(fileList) {
      const file = fileList[0];
      if (!file || !file.response) return;
      const { data } = file.response;
      if (!data) return;
      this.form.dictIcon = data;
    },
    onValid() {
      const result = { flag: FETCH_CODE.SUCCESS.KEY, data: this.form, msg: "" };
      return new Promise((resolve) => {
        this.$refs.formRef.validate((valid, errors) => {
          if (!valid) {
            result.flag = FETCH_CODE.WARNING.KEY;
            result.msg = Object.values(errors)[0]?.[0]?.message;
            resolve(result);
            return;
          }
          resolve(result);
        });
      });
    },
    onReset() {
      this.$refs.formRef.resetFields();
      this.form = {
        ...this.form,
        ...formEmpty,
      };
    },
    onInit() {
      if (this.data && this.data.id) {
        this.form = this.data;

        if (this.data.dictIcon) {
          this.fileList = [{
            uid: "-1",
            name: "icon.png",
            status: "done",
            url: this.data.dictIcon,
          }];
        }
      }
    },
  },
  mounted() {
    this.onInit();
  },
};
</script>

<style lang="less" scoped></style>
