<template>
  <div class="banner-list">
    <div class="search-head" style="position: relative;">
      <a-select
        :getPopupContainer="trigger => trigger.parentNode"
        v-model="bannerType"
        :placeholder="$t('system.banner.bannerType')"
        class="search-input"
      >
        <a-select-option value="">
          全部
        </a-select-option>
        <a-select-option
          v-for="op in bannerTypeList"
          :key="op.dictCode"
          :value="op.dictCode"
        >
          {{ op.dictName }}
        </a-select-option>
      </a-select>
      <a-button type="primary" @click="onSearch">
        {{ $t("button.filter") }}
      </a-button>
    </div>
    <ECTable
      showTitle
      :title="$t('system.banner.title')"
      :columns="columns"
      :data-source="list"
      bordered
      rowKey="id"
      key="id"
      :loading="loading"
      :pagination="false"
    >
      <div slot="tableHeaderRight" class="table-buttons">
        <a-button type="primary" @click="onAdd">
          {{ $t("system.banner.add") }}
        </a-button>
      </div>
      <template slot="state" slot-scope="text, record">
        <a-switch :checked="record.state" @change="(state) => onStateChange(record, state)" />
      </template>
      <template slot="bannerPictureUrl" slot-scope="text">
        <img style="width: 30px;max-height: 60px;" :src="text" alt="" v-if="text" />
        <span v-else>--</span>
      </template>
      <!-- 操作列 -->
      <template slot="action" slot-scope="text, record">
        <div class="table-operations">
          <a-button type="link" class="table-btn" @click="onEdit(record)">
            <a-icon type="edit" />
            {{ $t("button.edit") }}
          </a-button>
          <a-popconfirm
            :title="$t('button.confirmDelete')"
            :ok-text="$t('button.yes')"
            :cancel-text="$t('button.no')"
            @confirm="onDelete(record)"
          >
            <a-button type="link" class="table-btn danger">
              <a-icon type="delete" />
              {{ $t("button.remove") }}
            </a-button>
          </a-popconfirm>
        </div>
      </template>
    </ECTable>
    <a-modal
      :visible="formVisible"
      @cancel="onFormCancel"
      @ok="onFormConfirm"
      class="banner-form-modal"
      :maskClosable="false"
      destroyOnClose
      :title="$t('system.banner.modal')"
    >
      <BannerForm ref="formRef" :data="formRecord" />
    </a-modal>
  </div>
</template>

<script>
import ECTable from "@/components/ECTable";
import {
  delBanner,
  listBanner,
  saveBanner,
} from "@/pages/system/api";
import { FETCH_CODE } from "@/config";
import { showMsg } from "@/utils";
import BannerForm from "@/pages/system/banner/Form.vue";
import { getDic } from "@/api";
import { cloneDeep } from "lodash";
import { listDicFormatter } from "@/utils/tools";

export default {
  name: "BannerList",
  components: { BannerForm, ECTable },
  data() {
    return {
      bannerType: "",
      columns: [
        {
          title: this.$t("system.banner.cols.sort"),
          dataIndex: "sort",
          width: 80,
          align: "center",
        },
        {
          title: this.$t("system.banner.cols.bannerPictureUrl"),
          dataIndex: "bannerPictureUrl",
          width: 100,
          scopedSlots: { customRender: "bannerPictureUrl" },
          align: "center",
        },
        {
          title: this.$t("system.banner.cols.bannerLink"),
          dataIndex: "bannerLink",
          width: 200,
          align: "left",
        },
        {
          title: this.$t("system.banner.cols.bannerType"),
          dataIndex: "bannerTypeName",
          width: 100,
          align: "center",
        },
        {
          title: this.$t("system.banner.cols.state"),
          dataIndex: "state",
          width: 80,
          scopedSlots: { customRender: "state" },
          align: "center",
        },
        {
          title: this.$t("system.banner.cols.remark"),
          dataIndex: "remark",
          align: "left",
          width: 200,
        },
        {
          title: this.$t("action"),
          dataIndex: "action",
          key: "action",
          align: "center",
          scopedSlots: { customRender: "action" },
          width: 130,
        },
      ],
      list: [],
      loading: false,
      formVisible: false,
      formLoading: false,
      formRecord: {},
      bannerTypeList: [],
    };
  },
  methods: {
    onSearch() {
      this.getList();
    },
    onAdd() {
      this.formVisible = true;
    },
    onEdit(record) {
      this.formRecord = cloneDeep(record);
      this.formVisible = true;
    },
    async onDelete(record) {
      if (this.loading) return;
      this.loading = true;
      const res = await delBanner({ id: record.id });
      if (res.flag === FETCH_CODE.SUCCESS.KEY) {
        res.msg = this.$t("showMsg.success.delete");
      }
      showMsg(res);
      this.loading = false;
      if (res.flag === FETCH_CODE.SUCCESS.KEY) {
        this.getList();
      }
    },
    onFormCancel() {
      this.$refs.formRef.onReset();
      this.formVisible = false;
      this.formRecord = {};
    },
    async onFormConfirm() {
      if (this.formLoading) return;
      this.formLoading = true;
      const valid  = await this.$refs.formRef.onValid();
      if (valid.flag !== FETCH_CODE.SUCCESS.KEY) {
        showMsg(valid);
        this.formLoading = false;
        return;
      }
      const res = await saveBanner(valid.data);
      if (res.flag !== FETCH_CODE.SUCCESS.KEY) {
        showMsg(res);
        this.formLoading = false;
        return;
      }
      let msg = this.$t("showMsg.success.add");
      if (this.formRecord.id) {
        msg = this.$t("showMsg.success.save");
      }
      showMsg({ flag: FETCH_CODE.SUCCESS.KEY, msg });
      this.formLoading = false;
      this.onFormCancel();
      this.getList();
    },
    async getList() {
      this.loading = true;
      const params = {
        bannerType: this.bannerType,
      };
      const res = await listBanner(params);
      if (res.flag !== FETCH_CODE.SUCCESS.KEY) {
        showMsg(res);
        this.loading = false;
        return;
      }
      const list = res.data || [];
      this.list = await listDicFormatter(list, [
        { key: "bannerType", code: "banner_type" },
      ]);
      this.loading = false;
    },
    async getBannerType() {
      const res = await getDic({
        code: "banner_type",
      });
      if (res.flag !== FETCH_CODE.SUCCESS.KEY) return;
      this.bannerTypeList = res.data;
    },
    async onStateChange(record, state) {
      if (this.formLoading) return;
      this.formLoading = true;
      const res = await saveBanner({
        ...record,
        state,
      });
      if (res.flag !== FETCH_CODE.SUCCESS.KEY) {
        showMsg(res);
        this.formLoading = false;
        return;
      }
      showMsg({
        flag: FETCH_CODE.SUCCESS.KEY,
        msg: this.$t("showMsg.success.save"),
      });
      this.formLoading = false;
      this.getList();
    },
  },
  mounted() {
    this.getBannerType();
    this.getList();
  },
};
</script>

<style lang="less" scoped>
.search-head {
  padding: @ec-gutter20 0;

  .search-input {
    width: 300px;
    margin-right: @ec-gutter;
  }
}
</style>
