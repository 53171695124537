<template>
  <a-form-model
    ref="formRef"
    class="user-reset-pwd-form"
    :model="form"
    :rules="rules"
    :label-col="{ span: 5 }"
    :wrapper-col="{ span: 19 }"
  >
    <a-row>
      <a-col :span="24">
        <a-form-model-item
          :label="$t('hrManagement.staffList.form.name.label')"
          prop="name"
        >
          <a-input v-model="user.name" disabled />
        </a-form-model-item>
      </a-col>
      <a-col :span="24">
        <a-form-model-item
          :label="$t('hrManagement.staffList.form.mobilePhoneNumber.label')"
          prop="mobilePhoneNumber"
        >
          <a-input v-model="user.mobilePhoneNumber" disabled />
        </a-form-model-item>
      </a-col>
      <a-col :span="24">
        <a-form-model-item
          :label="$t('user.password.label')"
          prop="password"
        >
          <a-input-password
            v-model="form.password"
            :placeholder="$t('user.password.placeholder')"
          />
        </a-form-model-item>
      </a-col>
    </a-row>
  </a-form-model>
</template>

<script>
import { FETCH_CODE } from "@/config";

const Empty = {
  password: null,
};

export default {
  name: "ResetPwd",
  props: {
    user: {
      required: true,
      type: Object,
    },
  },
  data() {
    return {
      form: {
        ...Empty,
      },
      rules: {
        password: [
          { required: true, message: this.$t("user.password.validation") },
          { min: 6, message: this.$t("user.password.minLength") },
          { max: 24, message: this.$t("user.password.maxLength", { length: 24 }) },
        ],
      },
    };
  },
  methods: {
    onValid() {
      const result = { flag: FETCH_CODE.SUCCESS.KEY, data: this.form, msg: "" };
      return new Promise((resolve) => {
        this.$refs.formRef.validate((valid, errors) => {
          if (!valid) {
            result.flag = FETCH_CODE.WARNING.KEY;
            result.msg = Object.values(errors)[0]?.[0]?.message;
            resolve(result);
            return;
          }
          resolve(result);
        });
      });
    },
    onReset() {
      this.$refs.formRef.resetFields();
      this.form = {
        ...this.form,
        ...Empty,
      };
    },
  },
  mounted() {},
};
</script>

<style lang="less" scoped></style>
