<template>
  <div class="conf-list">
    <div class="search-head">
      <a-input
        size="large"
        v-model="searchText"
        :placeholder="$t('system.conf.search')"
        allowClear
        class="search-input"
        @keydown.enter="onSearch"
      />
      <a-button type="primary" @click="onSearch">
        {{ $t("button.filter") }}
      </a-button>
    </div>
    <ECTable
      showTitle
      :title="$t('system.conf.title')"
      :columns="columns"
      :data-source="list"
      bordered
      rowKey="id"
      key="id"
      :loading="loading"
      :pagination="{ total, current, pageSize }"
      @change="onTableChange"
    >
      <div slot="tableHeaderRight" class="table-buttons">
        <a-button type="primary" @click="onAdd">
          {{ $t("system.conf.add") }}
        </a-button>
      </div>
      <template slot="state" slot-scope="text, record">
        <a-switch :checked="record.state" />
      </template>
      <template slot="isEncryption" slot-scope="text, record">
        <a-switch :checked="record.isEncryption" />
      </template>
      <!-- 操作列 -->
      <template slot="action" slot-scope="text, record">
        <div class="table-operations">
          <a-button type="link" class="table-btn" @click="onEdit(record)">
            <a-icon type="edit" />
            {{ $t("button.edit") }}
          </a-button>
          <a-popconfirm
            :title="$t('button.confirmDelete')"
            :ok-text="$t('button.yes')"
            :cancel-text="$t('button.no')"
            @confirm="onDelete(record)"
          >
            <a-button type="link" class="table-btn danger">
              <a-icon type="delete" />
              {{ $t("button.remove") }}
            </a-button>
          </a-popconfirm>
        </div>
      </template>
    </ECTable>
    <a-modal
      :visible="formVisible"
      @cancel="onFormCancel"
      @ok="onFormConfirm"
      class="conf-form-modal"
      :maskClosable="false"
      destroyOnClose
      :title="$t('system.conf.modal')"
    >
      <DicForm ref="formRef" :data="formRecord" />
    </a-modal>
  </div>
</template>

<script>
import ECTable from "@/components/ECTable";
import {delConf, delDic, getDicPage, pageConf, saveConf, saveDic} from "@/pages/system/api";
import {cloneDeep, trim} from "lodash";
import { FETCH_CODE } from "@/config";
import { showMsg } from "@/utils";
import DicForm from "@/pages/system/conf/Form.vue";

export default {
  name: "ConfigList",
  components: { DicForm, ECTable },
  data() {
    return {
      searchText: "",
      columns: [
        {
          title: this.$t("system.conf.cols.sort"),
          dataIndex: "sort",
          width: 80,
          align: "center",
        },
        {
          title: this.$t("system.conf.cols.name"),
          dataIndex: "name",
          width: 150,
          align: "left",
        },
        {
          title: this.$t("system.conf.cols.code"),
          dataIndex: "code",
          width: 150,
          align: "left",
        },
        {
          title: this.$t("system.conf.cols.value"),
          dataIndex: "value",
          width: 150,
          align: "left",
        },
        {
          title: this.$t("system.conf.cols.delFlag"),
          dataIndex: "state",
          width: 80,
          scopedSlots: { customRender: "state" },
          align: "center",
        },
        {
          title: this.$t("system.conf.cols.isEncryption"),
          dataIndex: "isEncryption",
          width: 80,
          scopedSlots: { customRender: "isEncryption" },
          align: "center",
        },
        {
          title: this.$t("table.createBy"),
          dataIndex: "createBy",
          width: 120,
          align: "left",
        },
        {
          title: this.$t("table.createTime"),
          dataIndex: "createTime",
          width: 160,
          align: "center",
        },
        {
          title: this.$t("table.updateBy"),
          dataIndex: "updateBy",
          width: 120,
          align: "left",
        },
        {
          title: this.$t("table.updateTime"),
          dataIndex: "updateTime",
          width: 160,
          align: "center",
        },
        {
          title: this.$t("system.conf.cols.remark"),
          dataIndex: "remark",
          align: "left",
          width: 200,
        },
        {
          title: this.$t("action"),
          dataIndex: "action",
          key: "action",
          align: "center",
          scopedSlots: { customRender: "action" },
          width: 130,
          fixed: "right",
        },
      ],
      list: [],
      loading: false,
      total: 0,
      current: 1,
      pageSize: 10,
      formVisible: false,
      formLoading: false,
      formRecord: {},
    };
  },
  methods: {
    onSearch() {
      this.current = 1;
      this.getList();
    },
    onTableChange(page) {
      this.current = page.current;
      this.pageSize = page.pageSize;
      this.getList();
    },
    onAdd() {
      this.formVisible = true;
    },
    onEdit(record) {
      this.formRecord = cloneDeep(record);
      this.formVisible = true;
    },
    async onDelete(record) {
      if (this.loading) return;
      this.loading = true;
      const res = await delConf({ id: record.id });
      if (res.flag === FETCH_CODE.SUCCESS.KEY) {
        res.msg = this.$t("showMsg.success.delete");
      }
      showMsg(res);
      this.loading = false;
      if (res.flag === FETCH_CODE.SUCCESS.KEY) {
        this.getList();
      }
    },
    onFormCancel() {
      this.$refs.formRef.onReset();
      this.formVisible = false;
      this.formRecord = {};
    },
    async onFormConfirm() {
      if (this.formLoading) return;
      this.formLoading = true;
      const valid  = await this.$refs.formRef.onValid();
      if (valid.flag !== FETCH_CODE.SUCCESS.KEY) {
        showMsg(valid);
        this.formLoading = false;
        return;
      }
      const res = await saveConf(valid.data);
      if (res.flag !== FETCH_CODE.SUCCESS.KEY) {
        showMsg(res);
        this.formLoading = false;
        return;
      }
      let msg = this.$t("showMsg.success.add");
      if (this.formRecord.id) {
        msg = this.$t("showMsg.success.save");
      }
      showMsg({ flag: FETCH_CODE.SUCCESS.KEY, msg });
      this.formLoading = false;
      this.onFormCancel();
      this.getList();
    },
    async getList() {
      this.loading = true;
      const params = {
        pageNum: this.current,
        pageSize: this.pageSize,
        search: trim(this.searchText),
      };
      const res = await pageConf(params);
      if (res.flag !== FETCH_CODE.SUCCESS.KEY) {
        showMsg(res);
        this.loading = false;
        return;
      }
      this.list = res.data.records || [];
      this.current = res.data.current;
      this.total = res.data.total;
      this.pageSize = res.data.size;
      this.loading = false;
    },
  },
  mounted() {
    this.getList();
  },
};
</script>

<style lang="less" scoped>
.search-head {
  padding: @ec-gutter20 0;

  .search-input {
    width: 300px;
    margin-right: @ec-gutter;
  }
}
</style>
