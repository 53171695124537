<template>
  <a-form-model
    ref="formRef"
    :model="form"
    :rules="rules"
    :label-col="formItemLayout.labelCol"
    :wrapper-col="formItemLayout.wrapperCol"
  >
    <a-row>
      <a-col :span="24">
        <a-form-model-item
          :label="$t('system.org.form.companyId.label')"
          prop="companyId"
        >
          <a-select
            :getPopupContainer="trigger => trigger.parentNode"
            v-model="form.companyId"
            :placeholder="$t('system.org.form.companyId.placeholder')"
            allowClear
            @change="onCompanyIdChange"
          >
            <a-select-option
              v-for="op in companyList"
              :key="op.companyId"
              :value="op.companyId"
            >
              {{ op.name }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
      </a-col>
      <a-col :span="24">
        <a-form-model-item
          :label="$t('system.org.form.deptName.label')"
          prop="deptName"
        >
          <a-input
            v-model="form.deptName"
            :placeholder="$t('system.org.form.deptName.placeholder')"
          />
        </a-form-model-item>
      </a-col>
      <a-col :span="24">
        <a-form-model-item
          :label="$t('system.org.form.deptId.label')"
          prop="deptId"
        >
          <a-input
            v-model="form.deptId"
            :placeholder="$t('form.auto')"
            disabled
          />
        </a-form-model-item>
      </a-col>
      <a-col :span="24">
        <a-form-model-item
          :label="$t('system.org.form.parentId.label')"
          prop="parentId"
        >
          <a-select
            :getPopupContainer="trigger => trigger.parentNode"
            v-model="form.parentId"
            :placeholder="$t('system.org.form.parentId.placeholder')"
            allowClear
          >
            <a-select-option
              v-for="op in orgList"
              :key="op.deptId"
              :value="op.deptId"
            >
              {{ op.deptName }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
      </a-col>
      <a-col :span="24">
        <a-form-model-item
          :label="$t('system.org.form.remark.label')"
          prop="remark"
        >
          <a-textarea
            v-model="form.remark"
            :auto-size="{ minRows: 3, maxRows: 5 }"
            :placeholder="$t('system.org.form.remark.placeholder')"
          />
        </a-form-model-item>
      </a-col>
    </a-row>
  </a-form-model>
</template>

<script>
import { FETCH_CODE } from "@/config";
import { listCompany, listOrg } from "@/pages/system/api";
import { langMixin } from "@/mixins";

const formEmpty = {
  companyId: undefined,
  parentId: undefined,
  deptName: null,
  deptId: null,
  remark: null,
};

export default {
  name: "OrgForm",
  props: {
    data: Object,
  },
  mixins: [langMixin],
  data() {
    return {
      form: {
        ...formEmpty,
      },
      rules: {
        companyId: [
          { required: true, message: this.$t("system.org.form.companyId.required") },
        ],
        deptName: [
          { required: true, message: this.$t("system.org.form.deptName.required") },
          { max: 100, message: this.$t("system.org.form.deptName.max", { length: 100 }) },
        ],
        remark: [
          { max: 500, message: this.$t("system.org.form.remark.max", { length: 500 }) },
        ],
      },
      companyList: [],
      orgList: [],
    };
  },
  computed: {
    formItemLayout() {
      return this.calcFormItemLayout([6, 8]);
    }
  },
  methods: {
    onValid() {
      const result = { flag: FETCH_CODE.SUCCESS.KEY, data: this.form, msg: "" };
      return new Promise((resolve) => {
        this.$refs.formRef.validate((valid, errors) => {
          if (!valid) {
            result.flag = FETCH_CODE.WARNING.KEY;
            result.msg = Object.values(errors)[0]?.[0]?.message;
            resolve(result);
            return;
          }
          resolve(result);
        });
      });
    },
    onReset() {
      this.$refs.formRef.resetFields();
      this.form = {
        ...this.form,
        ...formEmpty,
      };
    },
    onInit() {
      this.getCompanyList();
      if (this.data && this.data.id) {
        this.form = this.data;
        if (this.form.companyId) {
          this.getOrgList();
        }
      }
    },
    async getCompanyList() {
      this.companyList = [];
      const res = await listCompany({
        name: "",
      });
      if (res.flag !== FETCH_CODE.SUCCESS.KEY) {
        return;
      }
      this.companyList = res.data || [];
    },
    async getOrgList() {
      this.orgList = [];
      if (!this.form.companyId) {
        return;
      }
      const res = await listOrg({
        companyId: this.form.companyId,
      });
      if (res.flag !== FETCH_CODE.SUCCESS.KEY) {
        return;
      }
      const orgList = res.data.filter((it) => it.deptId !== this.form.deptId);
      if (!this.form.deptId) {
        this.orgList = orgList;
        return;
      }
      // 过滤当前部门的子孙集
      const children = [];
      const findChild = (deptId, _children) => {
        const _child = orgList.find((it) => it.parentId === deptId);
        if (!_child) {
          return;
        }
        _children.push(_child.deptId);
        findChild(_child.deptId, _children);
      };
      findChild(this.form.deptId, children);
      this.orgList = orgList.filter((it) => !children.includes(it.deptId));
    },
    onCompanyIdChange(companyId) {
      this.form.companyId = companyId;
      this.getOrgList();
    },
  },
  mounted() {
    this.onInit();
  },
};
</script>

<style lang="less" scoped></style>
