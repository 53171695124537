<template>
  <a-modal
    :visible="visible"
    :title="title"
    :maskClosable="false"
    @ok="ok"
    @cancel="cancel"
    destroyOnClose
  >
    <a-form-model
      :model="form"
      :rules="rules"
      ref="form"
      :label-col="formItemLayout.labelCol"
      :wrapper-col="formItemLayout.wrapperCol"
      :colon="false"
    >
      <a-form-model-item
        :label="$t('system.exchange.list.company')"
        prop="companyId"
      >
        <a-select
          :getPopupContainer="trigger => trigger.parentNode"
          v-model="form.companyId"
          :placeholder="$t('system.exchange.list.company')"
        >
          <a-select-option
            v-for="i in selectList.companyList"
            :key="i.companyId"
            :value="i.companyId"
          >
            {{ i.name }}
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item
        :label="$t('system.exchange.list.from')"
        prop="fromCurrency"
      >
        <a-select
          :getPopupContainer="trigger => trigger.parentNode"
          v-model="form.fromCurrency"
          :placeholder="$t('system.exchange.list.from')"
          @change="value => onFromSelect(value, 1)"
        >
          <a-select-option
            v-for="i in selectList.currencyList"
            :key="i.dictCode"
            :value="i.dictCode"
          >
            {{ i.dictName }}
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item
        :label="$t('system.exchange.list.to')"
        prop="toCurrency"
      >
        <a-select
          :getPopupContainer="trigger => trigger.parentNode"
          v-model="form.toCurrency"
          :placeholder="$t('system.exchange.list.to')"
          @change="value => onFromSelect(value, 2)"
        >
          <a-select-option
            v-for="i in selectList.toList"
            :key="i.dictCode"
            :value="i.dictCode"
            :disabled="i.dictCode === form.fromCurrency"
          >
            {{ i.dictName }}
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item
        :label="$t('system.exchange.list.ratio')"
        prop="ratio"
      >
        <a-input-number
          v-model="form.ratio"
          :placehoder="$t('system.exchange.list.ratio')"
          :min="0"
          style="width: 100%;"
        />
      </a-form-model-item>
      <a-form-model-item :label="$t('member.acct.start')" prop="state">
        <a-switch v-model="form.state" />
      </a-form-model-item>
      <a-form-model-item
        :label="$t('system.exchange.list.remark')"
        prop="remark"
      >
        <a-textarea
          v-model="form.remark"
          :placeholder="$t('system.exchange.list.remark')"
        />
      </a-form-model-item>
    </a-form-model>
  </a-modal>
</template>

<script>
import { langMixin } from "@/mixins";

export default {
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    isEdit: {
      type: Boolean,
      default: false
    },
    selectList: {
      type: Object,
      default: () => ({ companyList: [], currencyList: [] })
    },
    item: {
      type: Object,
      default: () => {}
    }
  },
  mixins: [langMixin],
  data() {
    return {
      form: {
        fromCurrency: 'main_coin',
        toCurrency: undefined,
        companyId: this.$store.state.user.info.currentCompany.companyId,
        ratio: 1,
        remark: "",
        state: true
      },
      rules: {
        fromCurrency: [
          {
            required: true,
            message: this.$t("select") + this.$t("system.exchange.list.from"),
            trigger: "blur"
          }
        ],
        toCurrency: [
          {
            required: true,
            message: this.$t("select") + this.$t("system.exchange.list.to"),
            trigger: "blur"
          }
        ],
        companyId: [
          {
            required: true,
            message:
              this.$t("select") + this.$t("system.exchange.list.company"),
            trigger: "blur"
          }
        ],
        ratio: [
          {
            required: true,
            message:
              this.$t("input") +
              this.$t("right") +
              this.$t("system.exchange.list.ratio"),
            trigger: "blur"
          }
        ],
        remark: [
          {
            required: true,
            message: this.$t("input") + this.$t("system.exchange.list.remark"),
            trigger: "blur"
          }
        ]
      }
    };
  },
  computed: {
    title() {
      return `${
        this.isEdit ? this.$t("button.edit") : this.$t("button.newAdd")
      }${this.$t("system.exchange.list.title")}`;
    },
    formItemLayout() {
      return this.calcFormItemLayout([6, 8]);
    }
  },
  methods: {
    ok() {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.$emit("getForm", this.form);
        }
      });
    },
    cancel() {
      this.$refs.form.resetFields();
      this.$emit("update:visible", false);
    },
    onFromSelect(value, type = 1) {
      const key = type === 1 ? "fromCurrency" : "toCurrency";
      if (value) {
        this[key] =
          this.selectList.currencyList.find(i => i.dictCode === value)
            ?.dictName || "";
      } else {
        this[key] = "";
      }
    }
  },
  mounted() {
    if (this.isEdit) {
      this.form = this.item;
    }
  }
};
</script>

<style lang="less" scoped></style>
