// 菜单类型 1 菜单 2 功能
export const MenuType = [
  {
    id: 1,
    dictType: "menu_type",
    dictCode: 1,
    dictName: "菜单",
  },
  {
    id: 2,
    dictType: "menu_type",
    dictCode: 2,
    dictName: "按钮",
    dictIcon: "",
  },
];

// 菜单平台类型 1 后台 2 HR端
export const MenuSysType = [
  {
    id: 1,
    dictType: "menu_sys_type",
    dictCode: 1,
    dictName: "后台",
  },
  {
    id: 2,
    dictType: "menu_sys_type",
    dictCode: 2,
    dictName: "HR端",
  },
  {
    id: 3,
    dictType: "menu_sys_type",
    dictCode: 3,
    dictName: "员工",
  },
];

// 用户类型 1:后台 2:hr 3:员工
export const UserType = [
  {
    id: 1,
    dictType: "user_type",
    dictCode: 1,
    dictName: "后台",
  },
  {
    id: 2,
    dictType: "user_type",
    dictCode: 2,
    dictName: "HR",
  },
  {
    id: 3,
    dictType: "user_type",
    dictCode: 3,
    dictName: "员工",
  },
];

export const UserSex = [
  {
    id: 1,
    dictType: "user_sex",
    dictCode: false,
    dictName: "男",
  },
  {
    id: 2,
    dictType: "user_sex",
    dictCode: true,
    dictName: "女",
  },
];

// 在职状态 1 在职 2 离职
export const WorkingStatus = [
  {
    id: 1,
    dictType: "working_status",
    dictCode: 1,
    dictName: "在职",
  },
  {
    id: 2,
    dictType: "working_status",
    dictCode: 2,
    dictName: "离职",
  },
];

// 签约类型
export const CompanySignType = [
  {
    id: 1,
    dictType: "company_sign_type",
    dictCode: 1,
    dictName: "员工福利平台",
  },
  {
    id: 2,
    dictType: "company_sign_type",
    dictCode: 2,
    dictName: "背景调查",
  },
  {
    id: 3,
    dictType: "company_sign_type",
    dictCode: 3,
    dictName: "员工激励平台-小妙计",
  },
  {
    id: 4,
    dictType: "company_sign_type",
    dictCode: 4,
    dictName: "员工敬业度/满意度调研",
  },
];

// 签约状态 1：续存 2：到期 3:终止
export const CompanySignStatus = [
  {
    id: 1,
    dictType: "company_sign_status",
    dictCode: 1,
    dictName: "续存",
  },
  {
    id: 2,
    dictType: "company_sign_status",
    dictCode: 2,
    dictName: "到期",
  },
  {
    id: 3,
    dictType: "company_sign_status",
    dictCode: 3,
    dictName: "终止",
  },
];

// 角色类型 1：客服 2：商务
export const PersonRoleType = [
  {
    id: 1,
    dictType: "person_role_type",
    dictCode: 1,
    dictName: "客服",
  },
  {
    id: 2,
    dictType: "person_role_type",
    dictCode: 2,
    dictName: "商务",
    dictIcon: "",
  },
];
