<template>
  <div class="sys-benefits-list">
    <div class="search-head" style="position: relative;">
      <a-select
        :getPopupContainer="trigger => trigger.parentNode"
        v-model="benefitsType"
        :placeholder="$t('welfarePlat.companyBenefits.benefitsType.placeholder')"
        allowClear
        class="search-input"
      >
        <a-select-option
          v-for="op in benefitsTypeList"
          :key="op.dictCode"
          :value="op.dictCode"
        >
          {{ op.dictName }}
        </a-select-option>
      </a-select>
      <a-input
        size="large"
        v-model="searchText"
        allowClear
        :placeholder="$t('system.benefits.search')"
        class="search-input"
        @keydown.enter="onSearch"
      />
      <a-button type="primary" @click="onSearch">
        {{ $t("button.filter") }}
      </a-button>
    </div>
    <ECTable
      showTitle
      :title="$t('system.benefits.title')"
      :columns="columns"
      :data-source="list"
      bordered
      rowKey="id"
      key="id"
      :loading="loading"
      :pagination="{ total, current, pageSize }"
      @change="onTableChange"
      :row-selection="{
        selectedRowKeys: selectedRowKeys,
        onChange: this.onSelectChange,
      }"
    >
      <div slot="tableHeaderRight" class="table-buttons">
        <a-button type="primary" @click="onDistribution" style="margin-right: 10px;">
          {{ $t("system.benefits.distribution.btn") }}
        </a-button>
        <a-button type="primary" @click="onAdd">
          {{ $t("system.benefits.add") }}
        </a-button>
      </div>
      <template slot="state" slot-scope="text, record">
        <a-switch readonly :checked="record.state" />
      </template>
      <template slot="isAuto" slot-scope="text, record">
        <a-switch readonly :checked="record.isAuto" />
      </template>
      <template slot="isSysBenefits" slot-scope="text, record">
        <a-switch readonly :checked="record.isSysBenefits" />
      </template>
      <!--   排序   -->
      <template slot="index" slot-scope="text, record, index">
        <div>{{ index + 1 }}</div>
      </template>
      <!-- 操作列 -->
      <template slot="action" slot-scope="text, record">
        <div class="table-operations">
          <a-button type="link" class="table-btn" @click="onEdit(record)">
            <a-icon type="edit" />
            {{ $t("button.edit") }}
          </a-button>
          <a-popconfirm
            :title="$t('button.confirmDelete')"
            :ok-text="$t('button.yes')"
            :cancel-text="$t('button.no')"
            @confirm="onDelete(record)"
          >
            <a-button type="link" class="table-btn danger">
              <a-icon type="delete" />
              {{ $t("button.remove") }}
            </a-button>
          </a-popconfirm>
        </div>
      </template>
      <template slot="footerCustom">
        <div></div>
      </template>
    </ECTable>

    <CompanyWelfareFormModal
      :visible.sync="formVisible"
      @reload="getList"
      :data="formRecord"
      system
    />

    <DistributionCompanyListModal
      :visible.sync="distributionVisible"
      :benefitsList="selectedRows"
      @reload="getList"
    />
  </div>
</template>

<script>
import ECTable from "@/components/ECTable";
import { cloneDeep, trim } from "lodash";
import { FETCH_CODE } from "@/config";
import { showMsg } from "@/utils";
import { getDic } from "@/api";
import {
  delSysBenefits,
  pageSysBenefits
} from "@/pages/workspace/welfarePlatform/api";
import CompanyWelfareFormModal
  from "@/pages/home/components/welfare/CompanyWelfare/FormModal.vue";
import { listPointsShowFormatter, pointsShowFormatter } from "@/utils/tools";
import DistributionCompanyListModal
  from "@/pages/system/benefits/distribution/Modal.vue";

export default {
  name: "SysBenefitsList",
  components: { DistributionCompanyListModal, CompanyWelfareFormModal, ECTable },
  data() {
    return {
      searchText: "",
      benefitsType: undefined,
      columns: [
        {
          title: this.$t("system.dic.cols.index"),
          dataIndex: "index",
          width: 80,
          key: 'index',
          align: "center",
          scopedSlots: { customRender: "index" },
        },
        {
          title: this.$t("welfarePlat.autoManualRelease.list.col2"),
          dataIndex: "name",
          ellipsis: true,
          width: 150,
          align: "left",
        },
        {
          title: this.$t("welfarePlat.companyBenefits.benefitsType.label"),
          dataIndex: "benefitsTypeName",
          ellipsis: true,
          width: 120,
          align: "center",
        },
        {
          title: this.$t("welfarePlat.autoManualRelease.list.col3", { name: this.$store.state.user.platformConf.moneyName }),
          dataIndex: "pointsShow",
          ellipsis: true,
          width: 130,
          align: "right",
        },
        {
          title: this.$t("welfarePlat.companyBenefits.grantTime.label"),
          dataIndex: "grantTime",
          ellipsis: true,
          width: 160,
          align: "center",
        },
        {
          title: this.$t("welfarePlat.autoManualRelease.list.col6"),
          dataIndex: "state",
          ellipsis: true,
          width: 100,
          align: "center",
          scopedSlots: { customRender: "state" },
        },
        {
          title: this.$t("welfarePlat.companyBenefits.isAuto.label"),
          dataIndex: "isAuto",
          ellipsis: true,
          width: 100,
          align: "center",
          scopedSlots: { customRender: "isAuto" },
        },
        {
          title: this.$t("welfarePlat.autoManualRelease.list.col5"),
          dataIndex: "createTime",
          ellipsis: true,
          width: 160,
          align: "center",
        },
        {
          title: this.$t("welfarePlat.companyBenefits.remark.label"),
          dataIndex: "remark",
          ellipsis: true,
          width: 180,
          align: "left",
        },
        {
          title: this.$t("action"),
          dataIndex: "action",
          key: "action",
          align: "center",
          scopedSlots: { customRender: "action" },
          width: 150,
          fixed: "right",
        },
      ],
      list: [],
      loading: false,
      total: 0,
      current: 1,
      pageSize: 10,
      formVisible: false,
      formRecord: {
        isSysBenefits: true,
      },
      benefitsTypeList: [],
      selectedRowKeys: [],
      selectedRows: [],
      distributionVisible: false,
    };
  },
  methods: {
    onSearch() {
      this.current = 1;
      this.getList();
    },
    onTableChange(page) {
      this.current = page.current;
      this.pageSize = page.pageSize;
      this.getList();
    },
    onAdd() {
      this.formRecord = {
        isSysBenefits: true,
      };
      this.formVisible = true;
    },
    onEdit(record) {
      this.formRecord = {
        ...record,
        isSysBenefits: true,
        points: record.points ? pointsShowFormatter(record.points, true) : 0,
      };
      this.formVisible = true;
    },
    async onDelete(record) {
      if (this.loading) return;
      this.loading = true;
      const res = await delSysBenefits({ id: record.id });
      if (res.flag === FETCH_CODE.SUCCESS.KEY) {
        res.msg = this.$t("showMsg.success.delete");
      }
      showMsg(res);
      this.loading = false;
      if (res.flag === FETCH_CODE.SUCCESS.KEY) {
        this.getList();
      }
    },
    async getList() {
      this.loading = true;
      const params = {
        pageNum: this.current,
        pageSize: this.pageSize,
        search: trim(this.searchText),
        benefitsType: this.benefitsType,
      };
      const res = await pageSysBenefits(params);
      if (res.flag !== FETCH_CODE.SUCCESS.KEY) {
        showMsg(res);
        this.loading = false;
        return;
      }
      let list = res.data.records || [];
      list = listPointsShowFormatter(list, ["points"]);
      this.list = list.map((it) => {
        it.isSysBenefits = true;
        it.key = it.id;
        return it;
      });
      this.current = res.data.current;
      this.total = res.data.total;
      this.pageSize = res.data.size;
      this.loading = false;
    },
    async getBenefitsTypeList() {
      const res = await getDic({
        code: "benefits_type",
      });
      if (res.flag !== FETCH_CODE.SUCCESS.KEY) {
        return;
      }
      this.benefitsTypeList = res.data;
    },
    onDistribution() {
      if (!this.selectedRows.length) {
        showMsg({
          flag: FETCH_CODE.WARNING.KEY,
          msg: this.$t("system.benefits.distribution.emptySelected"),
        });
        return;
      }
      this.distributionVisible = true;
    },
    onSelectChange(selectedRowKeys) {
      console.log(selectedRowKeys);
      this.selectedRowKeys = selectedRowKeys;
      this.selectedRows = selectedRowKeys.map((id) => {
        const curr = this.list.find((it) => it.id === id);
        if (!curr) return undefined;
        return {
          ...curr,
        };
      }).filter((it) => it);
      console.log(this.selectedRows);
    },
  },
  mounted() {
    this.getBenefitsTypeList();
    this.getList();
  },
};
</script>

<style lang="less" scoped>
.search-head {
  padding: @ec-gutter20 0;

  .search-input {
    width: 300px;
    margin-right: @ec-gutter;
  }
}
</style>
