<template>
  <div class="org-list">
    <div class="search-head" style="position: relative;">
      <a-input
        size="large"
        v-model="searchText"
        :placeholder="$t('system.org.search')"
        allowClear
        class="search-input"
        @keydown.enter="onSearch"
      />
      <a-select
          :getPopupContainer="trigger => trigger.parentNode"
          v-model="companyId"
          :placeholder="$t('system.org.cols.companyId')"
          class="search-input"
      >
        <a-select-option
            v-for="op in companyList"
            :key="op.companyId"
            :value="op.companyId"
        >
          {{ op.name }}
        </a-select-option>
      </a-select>
      <a-button type="primary" @click="onSearch">
        {{ $t("button.filter") }}
      </a-button>
    </div>
    <ECTable
      showTitle
      :title="$t('system.org.title')"
      :columns="columns"
      :data-source="list"
      bordered
      rowKey="id"
      key="id"
      :loading="loading"
      :pagination="false"
      @change="onTableChange"
    >
      <div slot="tableHeaderRight" class="table-buttons">
        <a-button type="primary" @click="onAdd">
          {{ $t("system.org.add") }}
        </a-button>
      </div>
      <template slot="sort" slot-scope="text, record, index">
        {{ index + 1 }}
      </template>
      <template slot="delFlag" slot-scope="text, record">
        <a-switch :checked="record.delFlag === false" />
      </template>
      <!-- 操作列 -->
      <template slot="action" slot-scope="text, record">
        <div class="table-operations">
          <a-button type="link" class="table-btn" @click="onEdit(record)">
            <a-icon type="edit" />
            {{ $t("button.edit") }}
          </a-button>
          <a-popconfirm
            :title="$t('button.confirmDelete')"
            :ok-text="$t('button.yes')"
            :cancel-text="$t('button.no')"
            @confirm="onDelete(record)"
          >
            <a-button type="link" class="table-btn danger">
              <a-icon type="delete" />
              {{ $t("button.remove") }}
            </a-button>
          </a-popconfirm>
        </div>
      </template>
    </ECTable>
    <a-modal
      :visible="formVisible"
      @cancel="onFormCancel"
      @ok="onFormConfirm"
      class="org-form-modal"
      :maskClosable="false"
      destroyOnClose
      :title="$t('system.org.modal')"
    >
      <DicForm ref="formRef" :data="formRecord" />
    </a-modal>
  </div>
</template>

<script>
import ECTable from "@/components/ECTable";
import {
  addOrg,
  delOrg,
  listCompany,
  listOrg,
  updateOrg
} from "@/pages/system/api";
import { cloneDeep, trim } from "lodash";
import { FETCH_CODE } from "@/config";
import { showMsg } from "@/utils";
import DicForm from "@/pages/system/org/Form.vue";
import {list2Tree} from "@/utils/tools";

export default {
  name: "OrgList",
  components: { DicForm, ECTable },
  data() {
    return {
      searchText: "",
      companyId: "",
      companyList: [],
      columns: [
        // {
        //   title: this.$t("system.org.cols.sort"),
        //   dataIndex: "sort",
        //   scopedSlots: { customRender: "sort" },
        //   width: 80,
        //   align: "center",
        // },
        {
          title: this.$t("system.org.cols.deptName"),
          dataIndex: "deptName",
          width: 300,
          align: "left",
        },
        {
          title: this.$t("system.org.cols.deptId"),
          dataIndex: "deptId",
          width: 200,
          align: "left",
        },
        {
          title: this.$t("system.org.cols.companyId"),
          dataIndex: "companyId",
          width: 200,
          align: "left",
        },
        {
          title: this.$t("system.org.cols.delFlag"),
          dataIndex: "delFlag",
          width: 100,
          scopedSlots: { customRender: "delFlag" },
          align: "center",
        },
        {
          title: this.$t("table.createBy"),
          dataIndex: "createBy",
          width: 120,
          align: "left",
        },
        {
          title: this.$t("table.createTime"),
          dataIndex: "createTime",
          width: 160,
          align: "center",
        },
        {
          title: this.$t("table.updateBy"),
          dataIndex: "updateBy",
          width: 120,
          align: "left",
        },
        {
          title: this.$t("table.updateTime"),
          dataIndex: "updateTime",
          width: 160,
          align: "center",
        },
        {
          title: this.$t("system.org.cols.remark"),
          dataIndex: "remark",
          align: "left",
          width: 200,
        },
        {
          title: this.$t("action"),
          dataIndex: "action",
          key: "action",
          align: "center",
          scopedSlots: { customRender: "action" },
          width: 130,
          fixed: "right",
        },
      ],
      list: [],
      loading: false,
      formVisible: false,
      formLoading: false,
      formRecord: {},
    };
  },
  methods: {
    onSearch() {
      this.current = 1;
      this.getList();
    },
    onTableChange(page) {
      this.current = page.current;
      this.pageSize = page.pageSize;
      this.getList();
    },
    onAdd() {
      this.formVisible = true;
    },
    onEdit(record) {
      this.formRecord = cloneDeep(record);
      this.formVisible = true;
    },
    async onDelete(record) {
      if (this.loading) return;
      this.loading = true;
      const res = await delOrg({ id: record.id });
      if (res.flag === FETCH_CODE.SUCCESS.KEY) {
        res.msg = this.$t("showMsg.success.delete");
      }
      showMsg(res);
      this.loading = false;
      if (res.flag === FETCH_CODE.SUCCESS.KEY) {
        this.getList();
      }
    },
    onFormCancel() {
      this.$refs.formRef.onReset();
      this.formVisible = false;
      this.formRecord = {};
    },
    async onFormConfirm() {
      if (this.formLoading) return;
      this.formLoading = true;
      const valid  = await this.$refs.formRef.onValid();
      if (valid.flag !== FETCH_CODE.SUCCESS.KEY) {
        showMsg(valid);
        this.formLoading = false;
        return;
      }
      if (this.formRecord.id) {
        const res = await updateOrg(valid.data);
        if (res.flag !== FETCH_CODE.SUCCESS.KEY) {
          showMsg(res);
          this.formLoading = false;
          return;
        }
        showMsg({
          flag: FETCH_CODE.SUCCESS.KEY,
          msg: this.$t("showMsg.success.save"),
        });
      } else {
        const res = await addOrg(valid.data);
        if (res.flag !== FETCH_CODE.SUCCESS.KEY) {
          showMsg(res);
          this.formLoading = false;
          return;
        }
        showMsg({
          flag: FETCH_CODE.SUCCESS.KEY,
          msg: this.$t("showMsg.success.add"),
        });
      }

      this.formLoading = false;
      this.onFormCancel();
      this.getList();
    },
    async getList() {
      this.loading = true;
      const params = {
        companyId: this.companyId,
        name: trim(this.searchText),
      };
      const res = await listOrg(params);
      if (res.flag !== FETCH_CODE.SUCCESS.KEY) {
        showMsg(res);
        this.loading = false;
        return;
      }
      const list = res.data || [];
      list.forEach((it) => {
        it.key = it.id;
        if (it.parentId === "0") {
          it.parentId = undefined;
        }
      });
      this.list = list2Tree(list, {
        keyName: "deptId",
        parentKeyName: "parentId",
      });
      this.loading = false;
    },
    async getCompanyList() {
      this.companyList = [];
      const res = await listCompany({
        name: "",
      });
      if (res.flag !== FETCH_CODE.SUCCESS.KEY) {
        return;
      }
      this.companyList = res.data;
      this.companyId = this.companyList[0]?.companyId;
    },
  },
  async mounted() {
    await this.getCompanyList();
    this.getList();
  },
};
</script>

<style lang="less" scoped>
.search-head {
  padding: @ec-gutter20 0;

  .search-input {
    width: 300px;
    margin-right: @ec-gutter;
  }
}
</style>
