<template>
  <div>
    <ListFilter :form-list="formList" @reset="onReset" @filter="onSearch" />
    <ECTable
      rowKey="id"
      key="id"
      showTitle
      title=" "
      :loading="loading"
      :data-source="tableList"
      :columns="columns"
      :pagination="{ current, total, pageSize }"
      @change="onPageChange"
    >
      <template slot="tableHeaderRight">
        <a-button type="primary" @click="onAdd">
          {{ $t("button.newAdd") }}
        </a-button>
      </template>
      <template slot-scope="text, record" slot="action">
        <div class="table-operations">
          <a-button type="link" class="table-btn" @click="onEdit(record)">
            <a-icon type="edit" />
            {{ $t("button.edit") }}
          </a-button>
          <a-popconfirm
            :title="$t('button.confirmDelete')"
            :ok-text="$t('button.yes')"
            :cancel-text="$t('button.no')"
            @confirm="onDelete(record)"
          >
            <a-button type="link" class="table-btn danger">
              <a-icon type="delete" />
              {{ $t("button.remove") }}
            </a-button>
          </a-popconfirm>
        </div>
      </template>
      <template slot="state" slot-scope="text, record">
        <a-switch :checked="record.state" />
      </template>
    </ECTable>
    <template v-if="isShow">
      <ExchangeModal
        :item="item"
        :visible.sync="isShow"
        @getForm="getForm"
        :selectList="selectList"
        :is-edit="isEdit"
      />
    </template>
  </div>
</template>

<script>
import ListFilter from "@/pages/home/components/ListFilter.vue";
import ECTable from "@/components/ECTable";
import {
  getRatePage,
  listCompany,
  updateExchangeRate,
  deleteExchangeRate
} from "@/pages/system/api";
import { FETCH_CODE } from "@/config";
import { getDic } from "@/api";
import ExchangeModal from "./Modal.vue";
import { showMsg } from "@/utils";

export default {
  name: "RatioList",
  components: { ECTable, ListFilter, ExchangeModal },
  data() {
    return {
      current: 1,
      pageSize: 10,
      total: 0,
      tableList: [],
      loading: false,
      columns: [
        {
          dataIndex: "companyName",
          title: this.$t("system.exchange.list.company"),
          align: "center"
        },
        {
          dataIndex: "fromCurrencyName",
          title: this.$t("system.exchange.list.from"),
          align: "center"
        },
        {
          dataIndex: "toCurrencyName",
          title: this.$t("system.exchange.list.to"),
          align: "center"
        },
        {
          dataIndex: "ratio",
          title: this.$t("system.exchange.list.ratio"),
          align: "center"
        },
        {
          dataIndex: "remark",
          title: this.$t("system.exchange.list.remark"),
          align: "center"
        },
        {
          key: "state",
          dataIndex: "state",
          title: this.$t("member.acct.start"),
          align: "center",
          scopedSlots: { customRender: "state" }
        },
        {
          title: this.$t("action"),
          dataIndex: "action",
          key: "action",
          align: "center",
          scopedSlots: { customRender: "action" }
        }
      ],
      companyList: [],
      currencyList: [],
      form: {
        companyId: "",
        from: "",
        to: ""
      },
      isEdit: false,
      isShow: false,
      item: {},
      selectList: {
        companyList: [],
        currencyList: [],
        toList: [],
      },
    };
  },
  computed: {
    formList() {
      return [
        {
          label: this.$t("system.exchange.list.company"),
          type: "select",
          key: "companyId",
          options: this.companyList,
        },
        {
          label: this.$t("system.exchange.list.from"),
          type: "select",
          key: "from",
          options: this.selectList.currencyList,
        },
        {
          label: this.$t("system.exchange.list.to"),
          type: "select",
          key: "to",
          options: this.selectList.toList,
        }
      ];
    },
  },
  methods: {
    onPageChange(page) {
      this.current = page.current;
      this.pageSize = page.pageSize;
      this.getList();
    },
    onReset() {
      this.form = {};
    },
    onSearch(form) {
      this.form = form;
      this.current = 1;
      this.getList();
    },
    onAdd() {
      this.isEdit = false;
      this.isShow = true;
    },
    onEdit(item) {
      this.isEdit = true;
      this.item = item;
      this.isShow = true;
    },
    async onDelete(item) {
      const res = await deleteExchangeRate({ id: item.id })
      if (res.flag !== FETCH_CODE.SUCCESS.KEY) {
        showMsg({ flag: FETCH_CODE.ERROR.KEY, msg: res.msg })
        return;
      }
      showMsg({ flag: FETCH_CODE.SUCCESS.KEY, msg: this.$t("showMsg.success.delete") })
      this.getList();

    },
    async getCompanyList() {
      const res = await listCompany();
      if (res.flag !== FETCH_CODE.SUCCESS.KEY) {
        return;
      }
      this.companyList = res.data || [];
      this.companyList.forEach(c => {
        c.label = c.name;
        c.value = c.companyId;
      });
      this.selectList.companyList = this.companyList;
    },
    async getCurrencyList() {
      const res = await getDic({ code: "currency" });
      if (res.flag !== FETCH_CODE.SUCCESS.KEY) {
        return;
      }
      this.currencyList = res.data || [];
      this.currencyList.forEach(c => {
        if (c.dictCode === "benefits_coin") {
          c.dictName = this.$store.state.user.platformConf.moneyName;
        }
        c.label = c.dictName;
        c.value = c.dictCode;
      });
      this.selectList.currencyList = this.currencyList.filter(i => {
        return ["main_coin"].includes(i.dictCode);
      });
      this.selectList.toList = this.currencyList.filter(i => {
        return ["benefits_coin", "back_tone_coin", "survey_coin"].includes(i.dictCode);
      });
    },
    async getList() {
      if (this.loading) {
        return;
      }
      this.loading = true;
      const params = {
        current: this.current,
        pageSize: this.pageSize,
        companyId: this.form.companyId,
        fromCurrency: this.form.from,
        toCurrency: this.form.to
      };
      const res = await getRatePage(params);
      if (res.flag !== FETCH_CODE.SUCCESS.KEY) {
        this.loading = false;
         showMsg({ flag: FETCH_CODE.ERROR.KEY, msg: res.msg });
        return;
      }
      const tableList = res.data?.records || [];
      this.tableList = tableList.map((it) => {
        if (it.toCurrency === "benefits_coin") {
          it.toCurrencyName = this.$store.state.user.platformConf.moneyName;
        }
        return it;
      });
      this.current = res.data.current;
      this.pageSize = res.data.size;
      this.total = res.data.total;
      this.loading = false;
    },
    async getForm(form) {
      const params = {
        companyId: form.companyId,
        fromCurrency: form.fromCurrency,
        toCurrency: form.toCurrency,
        ratio: form.ratio,
        remark: form.remark,
        state: form.state
      };
      const res = await updateExchangeRate(params);
      if (res.flag !== FETCH_CODE.SUCCESS.KEY) {
         showMsg({ flag: FETCH_CODE.ERROR.KEY, msg: res.msg });
        return;
      }
      const msg = this.isEdit ? this.$t("showMsg.success.change") : this.$t("showMsg.success.add")
      showMsg({ flag: FETCH_CODE.SUCCESS.KEY, msg })
      this.isShow = false;
      this.getList();
    }
  },
  mounted() {
    this.getCurrencyList();
    this.getCompanyList();
    this.getList();
  }
};
</script>

<style lang="less" scoped>
.table-operations {
  display: flex;
  justify-content: center;
  align-items: center;

  .table-btn {
    &.danger {
      color: @error-color;
    }
  }
}
</style>
