<template>
  <a-form-model
    ref="formRef"
    :model="form"
    :rules="rules"
    :label-col="formItemLayout.labelCol"
    :wrapper-col="formItemLayout.wrapperCol"
  >
    <a-row>
      <a-col :span="24">
        <a-form-model-item
          :label="$t('system.user.form.name.label')"
          prop="name"
        >
          <a-input
            v-model="form.name"
            :placeholder="$t('system.user.form.name.placeholder')"
          />
        </a-form-model-item>
      </a-col>
      <a-col :span="24">
        <a-form-model-item
          :label="$t('system.user.form.companyId.label')"
          prop="companyId"
        >
          <a-select
            :getPopupContainer="trigger => trigger.parentNode"
            v-model="form.companyId"
            :placeholder="$t('system.user.form.companyId.placeholder')"
            allowClear
            @change="onCompanyChange"
          >
            <a-select-option
              v-for="op in companyList"
              :key="op.companyId"
              :value="op.companyId"
            >
              {{ op.name }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
      </a-col>
      <a-col :span="24">
        <a-form-model-item
          :label="$t('system.user.form.deptId.label')"
          prop="deptId"
        >
          <a-select
            :getPopupContainer="trigger => trigger.parentNode"
            v-model="form.deptId"
            :placeholder="$t('system.user.form.deptId.placeholder')"
            allowClear
          >
            <a-select-option
              v-for="op in deptList"
              :key="op.deptId"
              :value="op.deptId"
            >
              {{ op.deptName }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
      </a-col>
      <a-col :span="24">
        <a-form-model-item
          :label="$t('system.user.form.userType.label')"
          prop="userType"
        >
          <a-select
            :getPopupContainer="trigger => trigger.parentNode"
              v-model="form.userType"
              :placeholder="$t('system.user.form.userType.placeholder')"
              allowClear
          >
            <a-select-option
                v-for="op in userTypeList"
                :key="op.dictCode"
                :value="op.dictCode"
            >
              {{ op.dictName }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
      </a-col>
      <a-col :span="24">
        <a-form-model-item
          :label="$t('system.user.form.roleIds.label')"
          prop="roleIds"
        >
          <a-select
            :getPopupContainer="trigger => trigger.parentNode"
              mode="multiple"
              v-model="form.roleIds"
              :placeholder="$t('system.user.form.roleIds.placeholder')"
              allowClear
          >
            <a-select-option
                v-for="op in roleList"
                :key="op.roleId"
                :value="op.roleId"
            >
              {{ op.name }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
      </a-col>
      <a-col :span="24">
        <a-form-model-item
          :label="$t('system.user.form.master.label')"
          prop="master"
        >
          <a-switch v-model:checked="form.master" />
        </a-form-model-item>
      </a-col>
      <a-col :span="24">
        <a-form-model-item
          :label="$t('system.user.form.gender.label')"
          prop="gender"
        >
          <a-select
            :getPopupContainer="trigger => trigger.parentNode"
              v-model="form.gender"
              :placeholder="$t('system.user.form.gender.placeholder')"
              allowClear
          >
            <a-select-option
                v-for="op in genderList"
                :key="op.dictCode"
                :value="op.dictCode"
            >
              {{ op.dictName }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
      </a-col>
      <a-col :span="24">
        <a-form-model-item
          :label="$t('system.user.form.birthday.label')"
          prop="birthday"
        >
          <a-date-picker
              v-model="form.birthday"
              type="date"
              :placeholder="$t('system.user.form.birthday.placeholder')"
              style="width: 100%;"
          />
        </a-form-model-item>
      </a-col>
      <a-col :span="24">
        <a-form-model-item
            :label="$t('system.user.form.mobilePhoneNumber.label')"
            prop="mobilePhoneNumber"
        >
          <a-input
              v-model="form.mobilePhoneNumber"
              :placeholder="$t('system.user.form.mobilePhoneNumber.placeholder')"
          />
        </a-form-model-item>
      </a-col>
      <a-col :span="24">
        <a-form-model-item
            :label="$t('system.user.form.telephoneNumber.label')"
            prop="telephoneNumber"
        >
          <a-input
              v-model="form.telephoneNumber"
              :placeholder="$t('system.user.form.telephoneNumber.placeholder')"
          />
        </a-form-model-item>
      </a-col>
      <a-col :span="24">
        <a-form-model-item
            :label="$t('system.user.form.emailAddress.label')"
            prop="emailAddress"
        >
          <a-input
              v-model="form.emailAddress"
              :placeholder="$t('system.user.form.emailAddress.placeholder')"
          />
        </a-form-model-item>
      </a-col>
      <a-col :span="24">
        <a-form-model-item
            :label="$t('system.user.form.induction.label')"
            prop="induction"
        >
          <a-date-picker
              v-model="form.induction"
              type="date"
              :placeholder="$t('system.user.form.induction.placeholder')"
              style="width: 100%;"
          />
        </a-form-model-item>
      </a-col>
      <a-col :span="24">
        <a-form-model-item
            :label="$t('system.user.form.sendMsg.label')"
            prop="sendMsg"
        >
          <a-switch v-model:checked="form.sendMsg" />
        </a-form-model-item>
      </a-col>
    </a-row>
  </a-form-model>
</template>

<script>
import { DateFormat, FETCH_CODE, REG_EMAIL, REG_MOBILE } from "@/config";
import { UserSex, UserType } from "@/pages/system/config";
import { listCompany, listOrg, listRole } from "@/pages/system/api";
import moment from "moment";
import { langMixin } from "@/mixins";

const formEmpty = {
  name: null,
  companyId: undefined,
  deptId: undefined,
  userType: undefined,
  master: false,
  gender: undefined,
  birthday: null,
  mobilePhoneNumber: null,
  telephoneNumber: null,
  emailAddress: null,
  induction: null,
  sendMsg: true,
  remark: null,
};

export default {
  name: "UserForm",
  props: {
    data: Object,
  },
  mixins: [langMixin],
  data() {
    return {
      form: {
        ...formEmpty,
      },
      rules: {
        name: [
          { required: true, message: this.$t("system.user.form.name.required") },
          { max: 100, message: this.$t("system.user.form.name.max", { length: 100 }) },
        ],
        companyId: [
          { required: true, message: this.$t("system.user.form.companyId.required") },
        ],
        deptId: [
          // { required: true, message: this.$t("system.user.form.deptId.required") },
        ],
        userType: [
          { required: true, message: this.$t("system.user.form.userType.required") },
        ],
        // roleIds: [
        //   { required: true, message: this.$t("system.user.form.roleIds.required") },
        // ],
        mobilePhoneNumber: [
          { required: true, message: this.$t("system.user.form.mobilePhoneNumber.required") },
          { pattern: REG_MOBILE, message: this.$t("system.user.form.mobilePhoneNumber.mobile") },
        ],
        emailAddress: [
          { required: true, message: this.$t("system.user.form.emailAddress.required") },
          { pattern: REG_EMAIL, message: this.$t("system.user.form.emailAddress.email") },
        ],
      },
      userTypeList: UserType,
      roleList: [],
      orgTree: [],
      genderList: UserSex,
      companyList: [],
      deptList: [],
    };
  },
  computed: {
    formItemLayout() {
      return this.calcFormItemLayout([6, 8]);
    }
  },
  methods: {
    onValid() {
      const result = {
        flag: FETCH_CODE.SUCCESS.KEY,
        data: {
          ...this.form,
          userCompany: {
            companyId: this.form.companyId,
          },
          induction: this.form.induction ? moment(this.form.induction).format(DateFormat) : "",
        },
        msg: "",
      };
      return new Promise((resolve) => {
        this.$refs.formRef.validate((valid, errors) => {
          if (!valid) {
            result.flag = FETCH_CODE.WARNING.KEY;
            result.msg = Object.values(errors)[0]?.[0]?.message;
            resolve(result);
            return;
          }
          resolve(result);
        });
      });
    },
    onReset() {
      this.$refs.formRef.resetFields();
      this.form = {
        ...this.form,
        ...formEmpty,
      };
    },
    onInit() {
      this.getRoleList();
      this.getCompanyList();
      if (this.data && this.data.id) {
        this.form = this.data;

        if (this.data.dictIcon) {
          this.fileList = [{
            uid: "-1",
            name: "icon.png",
            status: "done",
            url: this.data.dictIcon,
          }];
        }
        this.getDeptList();
      }
    },
    async getRoleList() {
      this.roleList = [];
      const res = await listRole();
      if (res.flag !== FETCH_CODE.SUCCESS.KEY) {
        return;
      }
      this.roleList = res.data;
    },
    async getCompanyList() {
      this.companyList = [];
      const res = await listCompany({
        name: "",
      });
      let list = res.data || [];
      this.companyList = list;
    },
    onCompanyChange(companyId) {
      this.form.companyId = companyId;
      this.getDeptList();
      if (!this.form.companyId) {
        this.form.deptId = undefined;
      }
    },
    async getDeptList() {
      this.deptList = [];
      if (!this.form.companyId) return;
      const res = await listOrg({
        name: "",
        companyId: this.form.companyId,
      });
      this.deptList = res.data || [];
    },
  },
  mounted() {
    this.onInit();
  },
};
</script>

<style lang="less" scoped></style>
