<template>
  <a-modal
    :visible="visible"
    @cancel="onCancel"
    @ok="onConfirm"
    class="user-reset-pwd-modal"
    :maskClosable="false"
    destroyOnClose
    :title="$t('button.resetPwd')"
  >
    <ResetPwd ref="indexRef" :user="user" />
  </a-modal>
</template>

<script>
import ResetPwd from "@/pages/system/user/ResetPwd/Index.vue";
import { FETCH_CODE } from "@/config";
import { showMsg } from "@/utils";
import { resetUserPwd } from "@/pages/system/api";

export default {
  name: "ResetPwdModal",
  components: { ResetPwd },
  props: {
    visible: Boolean,
    user: {
      required: true,
      type: Object,
    },
  },
  data() {
    return {
      pending: false,
    };
  },
  methods: {
    onCancel() {
      this.$emit("update:visible", false);
    },
    async onConfirm() {
      if (this.pending) return;
      this.pending = true;
      const valid = await this.$refs.indexRef.onValid();
      if (valid.flag !== FETCH_CODE.SUCCESS.KEY) {
        showMsg(valid);
        this.pending = false;
        return;
      }
      const res = await resetUserPwd({
        userId: this.user.userId,
        ...valid.data,
      });
      if (res.flag !== FETCH_CODE.SUCCESS.KEY) {
        showMsg(res);
        this.pending = false;
        return;
      }
      showMsg({
        flag: FETCH_CODE.SUCCESS.KEY,
        msg: this.$t("showMsg.success.change"),
      });
      this.pending = false;
      this.$refs.indexRef.onReset();
      this.onCancel();
    },
  },
  mounted() {},
};
</script>

<style lang="less" scoped></style>
