<template>
  <a-modal
    :title="$t('system.benefits.distribution.title')"
    :visible="visible"
    @cancel="onCancel"
    @ok="onOk"
    class="welfare-employment-modal"
    :maskClosable="false"
    destroyOnClose
  >
    <DistributionCompanyList ref="indexRef" :benefitsList="benefitsList" />
  </a-modal>
</template>

<script>
import DistributionCompanyList
  from "@/pages/system/benefits/distribution/Index.vue";

export default {
  name: "DistributionCompanyListModal",
  components: { DistributionCompanyList },
  props: {
    visible: Boolean,
    benefitsList: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {};
  },
  methods: {
    onCancel() {
      this.$emit("update:visible", false);
    },
    async onOk() {
      const res = await this.$refs.indexRef.onSubmit();
      if (!res) {
        return;
      }
      this.onCancel();
      this.$emit("reload");
    },
  },
  mounted() {},
};
</script>

<style lang="less" scoped></style>
